import { Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RolesCalendarizationActions } from '../store/roles-calendarization.actions';
export interface DistributeWorkloadEvenlyDialogData {
  scenarioCtrId: number;
}

@Component({
  selector: 'app-distribute-workload-evenly-dialog',
  templateUrl: './distribute-workload-evenly-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DistributeWorkloadEvenlyDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DistributeWorkloadEvenlyDialogData,
    public dialogRef: MatDialogRef<DistributeWorkloadEvenlyDialogComponent>,
    private store: Store
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onYes(): void {
    this.store.dispatch(
      RolesCalendarizationActions.distributeEvenly({
        scenarioCtrId: this.data.scenarioCtrId,
      })
    );
    this.dialogRef.close();
  }
}
