import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssumptionsAndExclusionsApiService {
  constructor(private http: HttpClient) {}

  public getAssumptionsAndExclusions(ctrId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}scenarioctrs/${ctrId}/assumptionsandexclusions`
    );
  }

  public updateCtrAssumptions(ctrId: number, update: string): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/${ctrId}/assumptions`,
      { text: update }
    );
  }

  public updateCtrExclusions(ctrId: number, update: string): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/${ctrId}/exclusions`,
      { text: update }
    );
  }

  public updateCtrScopeAssumptions(
    sctrScopeId: number,
    update: string
  ): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/scope/${sctrScopeId}/assumptions`,
      { text: update }
    );
  }

  public updateCtrScopeExclusions(
    sctrScopeId: number,
    update: string
  ): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/scope/${sctrScopeId}/exclusions`,
      { text: update }
    );
  }

  public updateCtrScopeEquipmentAssumptions(
    sctrScopeId: number,
    equipmentId: number,
    update: string
  ): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/scope/${sctrScopeId}/equipment/${equipmentId}/assumptions`,
      { text: update }
    );
  }

  public updateCtrScopeEquipmentExclusions(
    sctrScopeId: number,
    equipmentId: number,
    update: string
  ): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}scenarioctrs/scope/${sctrScopeId}/equipment/${equipmentId}/exclusions`,
      { text: update }
    );
  }
}
