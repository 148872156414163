<h2
  mat-dialog-title
  data-test="edit-activity-dialog-title"
>{{ title }}</h2>
<mat-dialog-content>
  <app-edit-activity-form
    [(data)]="activityData"
    [canChangePath]="canChangePath"
    [originalScopeId]="originalScopeId"
    [originalActivityCode]="originalActivityCode"
    [warnWhenMoving]="!copyMode"
    (valid)="updateState($event)"
  ></app-edit-activity-form>
</mat-dialog-content>
<div
  class="button-right"
  mat-dialog-actions
>
  <button
    [mat-dialog-close]="false"
    color="primary"
    data-test="edit-activity-dialog-close-button"
    mat-button
  >Cancel</button>

  <button
    [disabled]="!canSave"
    [mat-dialog-close]="activityData"
    color="primary"
    data-test="edit-activity-dialog-save-button"
    mat-button
  >{{ actionLabel }}</button>
</div>