<div class="form-card">
  <div>
    <h2>
      <strong data-test="change-project-owner-title"
        >Change Project Owner</strong
      >
    </h2>
    <form [formGroup]="editOwnerFormGroup" class="form-card">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Entity</mat-label>
        <mat-select formControlName="entityId" data-test="entity-input">
          <mat-option
            *ngFor="let entity of entities$ | async"
            [value]="entity.entityId"
          >
            {{ entity.enggCenter }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Project Owner</mat-label>
        <input
          type="text"
          matInput
          formControlName="userId"
          [matAutocomplete]="auto2"
          data-test="project-owner-input"
        />
        <mat-autocomplete
          #auto2="matAutocomplete"
          autoActiveFirstOption
          [displayWith]="displayProjectOwner"
        >
          <mat-option
            *ngFor="let projectOwner of filteredProjectOwners$ | async"
            [value]="projectOwner.userId"
          >
            {{ projectOwner.fullName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-checkbox
        class="mat-checkbox-label"
        formControlName="makeOwnerOfAllScenarios"
        data-test="make-owner-all-scenarios-checkbox"
      >
        Make also the Owner of all scenarios</mat-checkbox
      >

      <mat-checkbox
        class="mat-checkbox-label"
        formControlName="makeRequestorOfAllCtr"
        data-test="make-owner-all-ctrs-checkbox"
      >
        Make also the requestor of all CTRs</mat-checkbox
      >
    </form>
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <div class="checkbox">
      <mat-checkbox
        (change)="sendNotification = !sendNotification"
        [checked]="sendNotification"
        data-test="send-notifications-checkbox"
        >Send Notifications</mat-checkbox
      >
    </div>
    <div class="buttons">
      <button mat-raised-button class="buttons-space" (click)="Cancel()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="isUpdateOwnerDisabled"
        (click)="updateOwner()"
        data-test="change-project-owner-save-button"
      >
        Save
      </button>
    </div>
  </div>
</div>
