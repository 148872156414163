<h2
  mat-dialog-title
  data-test="admin-edit-mode-info-icon"
>Edit and Read modes in Activities Admin</h2>
<mat-dialog-content class="message-text">
  <p>
    There is only one user at a time that can edit Activities in admin.
  </p>
  <p>
    If you unlock Edit mode, all other users will not be able to edit activities, while you are in Edit mode. They will see that you are working on activities.
  </p>
  <p>
    Your changes will not be applied until you click on “Apply changes” button.
  </p>
  <p>
    If your screen is idle for 5 minutes, your Edit mode will be locked automatically. Your changes will be temporary saved. Next user that unlocks Edit mode will have a choice to apply or discard your changes.
  </p>
</mat-dialog-content>
<div mat-dialog-actions>
  <button
    [mat-dialog-close]="true"
    color="primary"
    data-test="admin-edit-mode-info-cancel-button"
    mat-button
  >Close</button>
</div>