<div mat-dialog-title>
  <span class="dialog-title" data-test="about-ectr-dialog-title"
    >About ECTR</span
  >
</div>

<mat-dialog-content>
  <iframe
    class="center"
    [src]="videoUrl"
    width="640"
    height="360"
    frameborder="0"
    scrolling="no"
    allowfullscreen
  ></iframe>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
    color="primary"
    data-test="welcome-tutorial-continue-button"
    mat-button
    [mat-dialog-close]="true"
  >
    Continue
  </button>
</div>
