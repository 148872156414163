<h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content *ngIf="data.message || data.additionalValue">
  <p>
    <ng-container *ngFor="let line of data.message?.split('\n')"
      >{{ line }}<br
    /></ng-container>
  </p>

  <p>{{ data?.additionalValue }}</p>
</mat-dialog-content>
<div class="button-right" mat-dialog-actions>
  <button
    (click)="onDismiss()"
    color="primary"
    data-test="confirm-no-button"
    mat-button
  >
    {{ data.rejectionButtonLabel || 'No' }}
  </button>
  <button
    (click)="onConfirm()"
    color="primary"
    data-test="confirm-yes-button"
    mat-button
  >
    {{ data.confirmationButtonLabel || 'Yes' }}
  </button>
</div>
