import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import {
  ChangeLogDialogComponent,
  ChangeLogDialogData,
  ChangeLogDialogResult,
} from '@admin/activities-change-log/change-log-dialog/change-log-dialog.component';
import { LockOverrideAction } from '@core/lock/lock-api.service';
import { EndLockActions } from '@core/lock/lock.service';

import { ActivitiesChangeLogApiService } from './activities-change-log-api.service';
import { EnterEditModeDialogComponent } from './enter-edit-mode-dialog/enter-edit-mode-dialog.component';

@Injectable({ providedIn: 'root' })
export class ActivitiesChangeLogService {
  constructor(
    private activitiesChangeLogServiceApi: ActivitiesChangeLogApiService,
    private dialog: MatDialog
  ) {}

  private getLatestChanges$() {
    return this.activitiesChangeLogServiceApi.getLatestChanges();
  }

  public reviewTakeOverChanges(): Observable<
    ChangeLogDialogResult<LockOverrideAction>
  > {
    return this.getLatestChanges$().pipe(
      switchMap((data) =>
        data.changes.length > 0
          ? this.confirmTakeOverDialog(data)
          : of({ action: LockOverrideAction.DISCARD })
      )
    );
  }

  public reviewOwnChanges(): Observable<ChangeLogDialogResult<EndLockActions>> {
    return this.getLatestChanges$().pipe(
      switchMap((data) => this.confirmChangesDialog(data))
    );
  }

  private confirmChangesDialog(
    data
  ): Observable<ChangeLogDialogResult<EndLockActions>> {
    return this.dialog
      .open<
        ChangeLogDialogComponent,
        ChangeLogDialogData<EndLockActions>,
        ChangeLogDialogResult<EndLockActions>
      >(ChangeLogDialogComponent, {
        maxHeight: '95vh',
        maxWidth: '95vw',
        data: {
          title: 'Apply changes?',
          readonly: false,
          changesUserName: data.userName,
          changesUserEmail: data.userEmail,
          changesDate: data.createdOn,
          changesList: data.changes,
          showNotifyUsersCheckbox: true,
          buttons:
            data.changes.length > 0
              ? [
                  {
                    label: 'Continue editing',
                    action: EndLockActions.CONTINUE,
                  },
                  {
                    label: 'Discard changes & Exit',
                    action: EndLockActions.DISCARD,
                  },
                  {
                    label: 'Apply changes & Exit',
                    class: 'danger',
                    action: EndLockActions.APPLY,
                  },
                ]
              : [
                  {
                    label: 'Continue editing',
                    action: EndLockActions.CONTINUE,
                  },
                  {
                    label: 'Exit edit mode',
                    class: 'danger',
                    action: EndLockActions.DISCARD,
                  },
                ],
        },
      })
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        filter(({ action }) => action !== EndLockActions.CONTINUE)
      );
  }

  public openEnterEditModeDialog() {
    return this.dialog
      .open<EnterEditModeDialogComponent>(EnterEditModeDialogComponent)
      .afterClosed();
  }

  private confirmTakeOverDialog(data) {
    return this.dialog
      .open<
        ChangeLogDialogComponent,
        ChangeLogDialogData<LockOverrideAction>,
        ChangeLogDialogResult<LockOverrideAction>
      >(ChangeLogDialogComponent, {
        maxHeight: '95vh',
        maxWidth: '95vw',
        data: {
          title: 'Take over changes?',
          readonly: true,
          changesUserName: data.userName,
          changesUserEmail: data.userEmail,
          changesDate: data.createdOn,
          changesList: data.changes,
          showNotifyUsersCheckbox: true,
          buttons: [
            {
              label: 'Take over changes & continue editing',
              action: LockOverrideAction.TAKE,
            },
            {
              label: 'Discard changes & continue editing',
              action: LockOverrideAction.DISCARD,
            },
            {
              label: 'Apply previous changes & Exit',
              class: 'danger',
              action: LockOverrideAction.APPLY,
            },
          ],
        },
      })
      .afterClosed()
      .pipe(filter((v) => !!v));
  }
}
