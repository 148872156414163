import { Dictionary } from '@ngrx/entity';
import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import {
  CTRs_FEATURE_KEY,
  CalendarizationForProject,
  ICalendarizationCollectionState,
  calendarizationEntityAdapter,
} from './ctrs-calendarization.reducer';

export const selectCalendarizationCollectionState =
  createFeatureSelector<ICalendarizationCollectionState>(CTRs_FEATURE_KEY);

export const {
  selectAll: selectAllCalendarization,
  selectEntities: selectCalendarizationMap,
} = calendarizationEntityAdapter.getSelectors(
  selectCalendarizationCollectionState
);

export const selectCalendarizationCTRsByScenarioIdFactory = (
  scenarioId: number
): MemoizedSelector<
  object,
  CalendarizationForProject[],
  (s1: CalendarizationForProject[]) => CalendarizationForProject[]
> =>
  createSelector(selectAllCalendarization, (ctrs) =>
    ctrs.filter((ctr) => ctr.scenarioId === scenarioId)
  );

export const selectCtrCalendarizationByScenarioCtrId = (
  scenarioCtrId: number
): MemoizedSelector<
  object,
  CalendarizationForProject,
  (s1: Dictionary<CalendarizationForProject>) => CalendarizationForProject
> => createSelector(selectCalendarizationMap, (ctrs) => ctrs[scenarioCtrId]);
