import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

@Injectable({ providedIn: 'root' })
export class UserDashboardGuard implements CanActivate {
  private currentUser$ = this.store.select(selectCurrentInitializedUser).pipe(
    filter((v) => !!v),
    shareReplay(1)
  );

  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        if (user.isRequestor) {
          return this.router.parseUrl('/dashboard/requestor');
        }

        if (user.isEngineer) {
          return this.router.parseUrl('/dashboard/engineer');
        }

        return this.router.parseUrl('/unauthorized');
      })
    );
  }
}
