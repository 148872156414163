import {
  prodFeatureFlags,
  sharedEnvironmentConfiguration,
} from './shared-environment-configuration';
import { theme } from './theme';

export const environment = {
  ...sharedEnvironmentConfiguration,
  production: true,
  apiUrl: 'https://ectr-api.services.technipfmc.com/api/',
  socketUrl: 'https://ectr-api.services.technipfmc.com/api/ectrhub',
  gTag: {
    Key: 'G-RNH8GCTCQ9',
    EnableDebugMode: false,
  },
  ...prodFeatureFlags,
  ...theme,
};
