import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs-compat";

@Injectable({ providedIn: 'root' })
export class CalendarizationCtrsToEditService {
  private _checkedCtrsToEdit = new BehaviorSubject<any[]>([]);
  public checkedCtrsToEdit$: Observable<any[]> = this._checkedCtrsToEdit.asObservable();

  setCheckedCtrsToEdit(checkedCtrsToEdit: any[]) {
    this._checkedCtrsToEdit.next(checkedCtrsToEdit);
  }
}
