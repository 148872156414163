import { NgModule } from '@angular/core';
import { TimeagoModule } from 'ngx-timeago';

import { BreadcrumbsModule } from '@app/breadcrumbs/breadcrumbs.module';
import { UserAvatarModule } from '@app/user-avatar/user-avatar.module';
import { SharedModule } from '@shared/shared.module';

import { EventLogComponent } from './event-log.component';
import { MessageComponent } from './message/message.component';
import { CommentComponent } from './comment/comment.component';
import { ReplyCommentComponent } from './reply-comment/reply-comment.component';
import { FilterComponent } from './filter/filter.component';

@NgModule({
  declarations: [
    EventLogComponent,
    MessageComponent,
    CommentComponent,
    ReplyCommentComponent,
    FilterComponent
  ],
  imports: [
    SharedModule,
    UserAvatarModule,
    TimeagoModule,
    BreadcrumbsModule
  ],
  exports: [
    EventLogComponent
  ]
})
export class EventLogModule { }
