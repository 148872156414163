import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Creator, Store } from '@ngrx/store';
import { format } from 'date-fns';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  first,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { getMinifiedPxDsAction } from '@collections/pxds/store/pxds.actions';
import { selectPxDFactory } from '@collections/pxds/store/pxds.selectors';
import {
  lockAcquiredAction,
  lockExpiredAction,
  lockRemovedAction,
} from '@core/lock/store/lock.actions';
import { ContextAwareAction } from '@core/utils/context-aware-props';

import { AdminActivitiesApiService } from '../admin-activities-api.service';
import { AdminActivitiesService } from '../admin-activities.service';

import {
  addActivityAction,
  addActivityFailureAction,
  addActivitySuccessAction,
  addBlockAction,
  addBlockFailureAction,
  addBlockSuccessAction,
  addScopeAction,
  addScopeFailureAction,
  addScopeSuccessAction,
  deleteAdminActivityAction,
  deleteAdminActivityFailureAction,
  deleteAdminActivitySuccessAction,
  deleteAdminBlockAction,
  deleteAdminBlockFailureAction,
  deleteAdminBlockSuccessAction,
  deleteAdminScopeAction,
  deleteAdminScopeFailureAction,
  deleteAdminScopeSuccessAction,
  exportMasterDataAction,
  exportMasterDataFailureAction,
  exportMasterDataSuccessAction,
  getActivitiesAutocompletionDataAction,
  getActivitiesAutocompletionDataFailureAction,
  getActivitiesAutocompletionDataSuccessAction,
  getAdminActivityAction,
  getAdminActivityFailureAction,
  getAdminActivitySuccessAction,
  getAdminBlockAction,
  getAdminBlockActivitiesAction,
  getAdminBlockActivitiesFailureAction,
  getAdminBlockActivitiesSuccessAction,
  getAdminBlockFailureAction,
  getAdminBlockScopesAction,
  getAdminBlockScopesFailureAction,
  getAdminBlockScopesSuccessAction,
  getAdminBlockSuccessAction,
  getAdminPxDBlocksAction,
  getAdminPxDBlocksFailureAction,
  getAdminPxDBlocksSuccessAction,
  getAdminScopeAction,
  getAdminScopeFailureAction,
  getAdminScopeSuccessAction,
  getScopesAutocompletionDataAction,
  getScopesAutocompletionDataFailureAction,
  getScopesAutocompletionDataSuccessAction,
  openAddActivityAction,
  openAddBlockAction,
  openAddScopeAction,
  openCopyActivityAction,
  openCopyBlockAction,
  openCopyScopeAction,
  openEditActivityAction,
  openEditBlockAction,
  openEditScopeAction,
  openReorderBlockScopesAction,
  openReorderPxdBlocksAction,
  openReorderScopeActivitiesAction,
  reloadMasterdataAction,
  requestRemoveActivityAction,
  requestRemoveBlockAction,
  requestRemoveScopeAction,
  updateActivityAction,
  updateActivityFailureAction,
  updateActivitySuccessAction,
  updateBlockAction,
  updateBlockFailureAction,
  updateBlockScopesOrderAction,
  updateBlockScopesOrderFailureAction,
  updateBlockScopesOrderSuccessAction,
  updateBlockSuccessAction,
  updatePxDBlocksOrderAction,
  updatePxDBlocksOrderFailureAction,
  updatePxDBlocksOrderSuccessAction,
  updateScopeAction,
  updateScopeActivitiesOrderAction,
  updateScopeActivitiesOrderFailureAction,
  updateScopeActivitiesOrderSuccessAction,
  updateScopeFailureAction,
  updateScopeSuccessAction,
} from './admin-activities.actions';

type SubjectsType<T> = {
  [P in keyof T]: BehaviorSubject<T[P] extends Observable<infer X> ? X : never>;
};

function cachedRequestEffectFactory<
  DATA extends SubjectsType<ReturnType<METHOD>> = any, // TODO: finish configuration
  T extends ActionCreator<string, Creator<any[], Action>> = ActionCreator<
    string,
    Creator<any[], Action>
  >,
  METHOD extends (action: ReturnType<T>) => Observable<DATA> = (
    action: ReturnType<T>
  ) => Observable<DATA>,
  S extends ActionCreator<
    string,
    Creator<[Omit<ContextAwareAction<DATA>, 'id' | 'type'>], ContextAwareAction>
  > = ActionCreator<
    string,
    Creator<[Omit<ContextAwareAction<DATA>, 'id' | 'type'>], ContextAwareAction>
  >,
  E extends ActionCreator<
    string,
    Creator<any[], ContextAwareAction>
  > = ActionCreator<string, Creator<any[], ContextAwareAction>>
>(
  actions$: Observable<Action>,
  triggerAction: T,
  successAction: S,
  errorAction: E,
  apiMethod: METHOD,
  getCacheKey: (action: ReturnType<T>) => string,
  cacheTimeout = 0
) {
  const cache: Record<number, Observable<Action>> = {};
  return createEffect(() =>
    actions$.pipe(
      ofType(triggerAction),
      mergeMap<ReturnType<T>, Observable<Action>>((action) => {
        const key = getCacheKey(action);

        if (!cache[key]) {
          cache[key] = apiMethod(action).pipe(
            map((payload) =>
              successAction({
                trigger: action,
                context: 'AdminActivitiesEffects::cachedRequestEffect',
                payload,
              })
            ),
            catchError((payload) =>
              of(
                errorAction({
                  trigger: action,
                  context: 'AdminActivitiesEffects::cachedRequestEffect',
                  payload,
                })
              )
            ),
            tap(() => {
              setTimeout(() => {
                cache[key] = null;
              }, cacheTimeout);
            }),
            shareReplay(1)
          );
        }

        return cache[key];
      })
    )
  );
}

function singleRequestEffectFactory<
  DATA extends SubjectsType<ReturnType<METHOD>> = any, // TODO: finish configuration
  T extends ActionCreator<string, Creator<any[], Action>> = ActionCreator<
    string,
    Creator<any[], Action>
  >,
  METHOD extends (action: ReturnType<T>) => Observable<DATA> = (
    action: ReturnType<T>
  ) => Observable<DATA>,
  S extends ActionCreator<
    string,
    Creator<[Omit<ContextAwareAction<DATA>, 'id' | 'type'>], ContextAwareAction>
  > = ActionCreator<
    string,
    Creator<[Omit<ContextAwareAction<DATA>, 'id' | 'type'>], ContextAwareAction>
  >,
  E extends ActionCreator<
    string,
    Creator<any[], ContextAwareAction>
  > = ActionCreator<string, Creator<any[], ContextAwareAction>>
>(
  actions$: Observable<Action>,
  triggerAction: T,
  successAction: S,
  errorAction: E,
  apiMethod: METHOD
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(triggerAction),
      switchMap<ReturnType<T>, Observable<Action>>((action) =>
        apiMethod(action).pipe(
          map((payload) =>
            successAction({
              trigger: action,
              context: `AdminActivitiesEffects::singleRequestEffect`,
              payload,
            })
          ),
          catchError((payload) =>
            of(
              errorAction({
                trigger: action,
                context: `AdminActivitiesEffects::singleRequestEffect`,
                payload,
              })
            )
          )
        )
      )
    )
  );
}

@Injectable()
export class AdminActivitiesEffects {
  constructor(
    private actions$: Actions,
    private adminActivitiesApiService: AdminActivitiesApiService,
    private adminActivitiesService: AdminActivitiesService,
    private store: Store,
    private snackBar: MatSnackBar
  ) {}

  // General Masterdata

  public exportMasterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportMasterDataAction),
      exhaustMap((action) =>
        this.adminActivitiesApiService.exportMasterData().pipe(
          tap((blob) => {
            saveAs(
              blob,
              `masterdata-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}`
            );
          }),
          map(() =>
            exportMasterDataSuccessAction({
              trigger: action,
              context: 'AdminActivitiesEffects::exportMasterData$',
            })
          ),
          catchError(() =>
            of(
              exportMasterDataFailureAction({
                trigger: action,
                context: 'AdminActivitiesEffects::exportMasterData$',
              })
            )
          )
        )
      )
    )
  );

  public apiCallSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportMasterDataSuccessAction),
        tap(() => {
          this.snackBar.open('Exporting masterdata success!', null, {
            duration: 3000,
          });
        })
      ),
    { dispatch: false }
  );

  public apiCallError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportMasterDataFailureAction),
        tap(() => {
          this.snackBar.open('Exporting masterdata failed!', null, {
            duration: 3000,
          });
        })
      ),
    { dispatch: false }
  );

  public lockChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockAcquiredAction, lockExpiredAction, lockRemovedAction),
      map((action) =>
        reloadMasterdataAction({
          trigger: action,
          context: 'AdminActivitiesEffects::lockChange$',
        })
      )
    )
  );

  public reloadMasterdata$ = createEffect(() => {
    const triggerActionTypes = [
      getAdminPxDBlocksAction,
      getAdminBlockScopesAction,
      getAdminBlockActivitiesAction,
      getMinifiedPxDsAction,
    ];

    return this.actions$.pipe(
      ofType(reloadMasterdataAction),
      withLatestFrom(
        ...triggerActionTypes.map((actionType) =>
          this.actions$.pipe(
            ofType(actionType),
            map((action) => ({ action, actionType })),
            startWith(null),
            shareReplay(1)
          )
        )
      ),
      mergeMap(([, ...cachedActions]) =>
        cachedActions
          .filter((action) => !!action)
          .map(({ action, actionType }) =>
            actionType({
              trigger: [reloadMasterdataAction, action],
              context: 'AdminActivitiesEffects::reloadMasterdata$',
              payload: action.payload as any,
            })
          )
      )
    );
  });

  // Activities

  // Activity Dialogs

  public openAddActivityDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddActivityAction),
      switchMap((action) =>
        this.adminActivitiesService.openAddActivity(action.payload.scopeId)
      )
    )
  );

  public openEditActivityDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEditActivityAction),
      switchMap((action) =>
        this.adminActivitiesService.openEditActivity(action.payload.activityId)
      )
    )
  );

  public openCopyActivityDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCopyActivityAction),
      switchMap((action) =>
        this.adminActivitiesService.openCopyActivity(action.payload.activityId)
      )
    )
  );

  public openReorderScopeActivitiesDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openReorderScopeActivitiesAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openReorderScopeActivities(action.payload.scopeId)
          .pipe(
            map((activities) =>
              updateScopeActivitiesOrderAction({
                context:
                  'AdminActivitiesEffects::openReorderScopeActivitiesDialog$',
                payload: {
                  scopeId: action.payload.scopeId,
                  activities,
                },
              })
            )
          )
      )
    )
  );

  public openRemoveActivityDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestRemoveActivityAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openConfirmRemove(
            `Remove activity ${action.payload.activityCode}`,
            'Do you really want to remove this activity?'
          )
          .pipe(
            mapTo(
              deleteAdminActivityAction({
                trigger: action,
                context: 'AdminActivitiesEffects::openRemoveActivityDialog$',
                payload: { activityId: action.payload.id },
              })
            )
          )
      )
    )
  );

  // Activities API

  public getActivitiesAutocompletionData$ = cachedRequestEffectFactory(
    this.actions$,
    getActivitiesAutocompletionDataAction,
    getActivitiesAutocompletionDataSuccessAction,
    getActivitiesAutocompletionDataFailureAction,
    () => this.adminActivitiesApiService.getActivitiesAutocompletionData(),
    () => 'activities-autocompletion'
  );

  public getAdminActivity$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminActivityAction,
    getAdminActivitySuccessAction,
    getAdminActivityFailureAction,
    (action) =>
      this.adminActivitiesApiService.getActivity(action.payload.activityId),
    (action) => `${action.payload.activityId}`
  );

  public getAdminBlockActivities$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminBlockActivitiesAction,
    getAdminBlockActivitiesSuccessAction,
    getAdminBlockActivitiesFailureAction,
    (action) =>
      this.adminActivitiesApiService.getBlockActivities(action.payload.blockId),
    (action) => `${action.payload.blockId}`
  );

  public addActivity$ = singleRequestEffectFactory(
    this.actions$,
    addActivityAction,
    addActivitySuccessAction,
    addActivityFailureAction,
    (action) => this.adminActivitiesApiService.postActivity(action.payload)
  );

  public updateActivity$ = singleRequestEffectFactory(
    this.actions$,
    updateActivityAction,
    updateActivitySuccessAction,
    updateActivityFailureAction,
    (action) => this.adminActivitiesApiService.putActivity(action.payload)
  );

  public deleteAdminActivity$ = singleRequestEffectFactory(
    this.actions$,
    deleteAdminActivityAction,
    deleteAdminActivitySuccessAction,
    deleteAdminActivityFailureAction,
    (action) =>
      this.adminActivitiesApiService.deleteAdminActivity(
        action.payload.activityId
      )
  );

  public saveScopeActivitiesOrder$ = singleRequestEffectFactory(
    this.actions$,
    updateScopeActivitiesOrderAction,
    updateScopeActivitiesOrderSuccessAction,
    updateScopeActivitiesOrderFailureAction,
    (action) =>
      this.adminActivitiesApiService.patchActivityOrder(
        action.payload.scopeId,
        action.payload.activities
      )
  );

  // Scopes

  // Scopes Dialogs

  public openAddScopeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddScopeAction),
      switchMap((action) =>
        this.adminActivitiesService.openAddScope(action.payload.blockId)
      )
    )
  );

  public openEditScopeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEditScopeAction),
      switchMap((action) =>
        this.adminActivitiesService.openEditScope(action.payload.scopeId)
      )
    )
  );

  public openCopyScopeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCopyScopeAction),
      switchMap((action) =>
        this.adminActivitiesService.openCopyScope(action.payload.scopeId)
      )
    )
  );

  public openReorderBlockScopesDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openReorderBlockScopesAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openReorderBlockScopes(action.payload.blockId)
          .pipe(
            map((scopes) =>
              updateBlockScopesOrderAction({
                context:
                  'AdminActivitiesEffects::openReorderBlockScopesDialog$',
                payload: {
                  blockId: action.payload.blockId,
                  scopes,
                },
              })
            )
          )
      )
    )
  );

  public openRemoveScopeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestRemoveScopeAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openConfirmRemove(
            `Remove scope ${action.payload.code}`,
            'Do you really want to remove this scope?'
          )
          .pipe(
            mapTo(
              deleteAdminScopeAction({
                trigger: action,
                context: 'AdminActivitiesEffects::openRemoveScopeDialog$',
                payload: action.payload,
              })
            )
          )
      )
    )
  );

  // Scope Update trigger

  public updateScopesAfterScopeAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addScopeSuccessAction),
      map((action) =>
        getAdminBlockScopesAction({
          trigger: action,
          context: 'AdminActivitiesEffects:updateScopesAfterActivityAdd',
          payload: { blockId: action.payload.blockId },
        })
      )
    )
  );

  // Scopes API

  public getScopesAutocompletionData$ = cachedRequestEffectFactory(
    this.actions$,
    getScopesAutocompletionDataAction,
    getScopesAutocompletionDataSuccessAction,
    getScopesAutocompletionDataFailureAction,
    () => this.adminActivitiesApiService.getScopesAutocompletionData(),
    () => 'scopes-autocompletion'
  );

  public getAdminScope$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminScopeAction,
    getAdminScopeSuccessAction,
    getAdminScopeFailureAction,
    (action) =>
      this.adminActivitiesApiService.getAdminScope(action.payload.scopeId),
    (action) => `${action.payload.scopeId}`
  );

  public getAdminBlockScopes$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminBlockScopesAction,
    getAdminBlockScopesSuccessAction,
    getAdminBlockScopesFailureAction,
    (action) =>
      this.adminActivitiesApiService.getBlockScopes(action.payload.blockId),
    (action) => `${action.payload.blockId}`
  );

  public addScope$ = singleRequestEffectFactory(
    this.actions$,
    addScopeAction,
    addScopeSuccessAction,
    addScopeFailureAction,
    (action) => this.adminActivitiesApiService.postScope(action.payload)
  );

  public updateScope$ = singleRequestEffectFactory(
    this.actions$,
    updateScopeAction,
    updateScopeSuccessAction,
    updateScopeFailureAction,
    (action) => this.adminActivitiesApiService.putScope(action.payload)
  );

  public deleteAdminScope$ = singleRequestEffectFactory(
    this.actions$,
    deleteAdminScopeAction,
    deleteAdminScopeSuccessAction,
    deleteAdminScopeFailureAction,
    (action) =>
      this.adminActivitiesApiService.deleteAdminScope(action.payload.scopeId)
  );

  public saveBlockScopesOrder$ = singleRequestEffectFactory(
    this.actions$,
    updateBlockScopesOrderAction,
    updateBlockScopesOrderSuccessAction,
    updateBlockScopesOrderFailureAction,
    (action) =>
      this.adminActivitiesApiService.patchScopeOrder(
        action.payload.blockId,
        action.payload.scopes
      )
  );

  // Blocks

  // Block Dialogs

  public openAddBlockDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddBlockAction),
      switchMap((action) =>
        this.adminActivitiesService.openAddBlock(action.payload.ctrId)
      )
    )
  );

  public openEditBlockDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEditBlockAction),
      switchMap((action) =>
        this.adminActivitiesService.openEditBlock(action.payload.blockId)
      )
    )
  );

  public openCopyBlockDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCopyBlockAction),
      switchMap((action) =>
        this.adminActivitiesService.openCopyBlock(action.payload.blockId)
      )
    )
  );

  public openReorderPxDBlocksDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openReorderPxdBlocksAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openReorderPxDBlocks(action.payload.ctrId)
          .pipe(
            map((blocks) =>
              updatePxDBlocksOrderAction({
                context: 'AdminActivitiesEffects::openReorderPxDBlocksDialog$',
                payload: {
                  ctrId: action.payload.ctrId,
                  blocks,
                },
              })
            )
          )
      )
    )
  );

  public openRemoveBlockDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestRemoveBlockAction),
      switchMap((action) =>
        this.adminActivitiesService
          .openConfirmRemove(
            `Remove block ${action.payload.blockCode}`,
            'Do you really want to remove this block?'
          )
          .pipe(
            mapTo(
              deleteAdminBlockAction({
                trigger: action,
                context: 'AdminActivitiesEffects::openRemoveBlockDialog$',
                payload: { blockId: action.payload.blockId },
              })
            )
          )
      )
    )
  );

  // Blocks API

  public getAdminBlock$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminBlockAction,
    getAdminBlockSuccessAction,
    getAdminBlockFailureAction,
    (action) =>
      this.adminActivitiesApiService.getAdminBlock(action.payload.blockId),
    (action) => `${action.payload.blockId}`
  );

  public getAdminPxDBlocks$ = cachedRequestEffectFactory(
    this.actions$,
    getAdminPxDBlocksAction,
    getAdminPxDBlocksSuccessAction,
    getAdminPxDBlocksFailureAction,
    (action) =>
      this.adminActivitiesApiService.getPxDBlocks(
        action.payload.productId,
        action.payload.disciplineId
      ),
    (action) => `${action.payload.productId}:${action.payload.disciplineId}`
  );

  public addBlock$ = singleRequestEffectFactory(
    this.actions$,
    addBlockAction,
    addBlockSuccessAction,
    addBlockFailureAction,
    (action) => this.adminActivitiesApiService.postBlock(action.payload)
  );

  public updateBlock$ = singleRequestEffectFactory(
    this.actions$,
    updateBlockAction,
    updateBlockSuccessAction,
    updateBlockFailureAction,
    (action) => this.adminActivitiesApiService.putBlock(action.payload)
  );

  public deleteAdminBlock$ = singleRequestEffectFactory(
    this.actions$,
    deleteAdminBlockAction,
    deleteAdminBlockSuccessAction,
    deleteAdminBlockFailureAction,
    (action) =>
      this.adminActivitiesApiService.deleteAdminBlock(action.payload.blockId)
  );

  public savePxDBlocksOrder$ = singleRequestEffectFactory(
    this.actions$,
    updatePxDBlocksOrderAction,
    updatePxDBlocksOrderSuccessAction,
    updatePxDBlocksOrderFailureAction,
    (action) =>
      this.adminActivitiesApiService.patchBlockOrder(
        action.payload.ctrId,
        action.payload.blocks
      )
  );

  // Block utils

  /**
   * When amount of block changes in PxD it's flags about if masterdata exists can also change.
   * So to update UI we need to request ne PxD list state after those changes.
   */
  public updatePxDsAfterBlockChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        addBlockSuccessAction,
        updateBlockSuccessAction
        // deleteAdminBlockSuccessAction // TODO: needs to get businessSegmentId before its deleted
      ),
      switchMap((action) =>
        this.store.select(selectPxDFactory(action.payload.ctrId)).pipe(
          first(),
          map(({ businessSegmentId }) =>
            getMinifiedPxDsAction({
              trigger: action,
              context: 'AdminActivitiesEffects::updatePxDsAfterBlockChanges$',
              payload: { businessSegmentId },
            })
          )
        )
      )
    )
  );
}
