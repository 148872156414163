import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CalendarizationCtrsToEditService } from '@app/calendarization/calendarization-ctrsToEdit.service';
import {
  getCtrsCalendarizationAction,
  updateScenarioCtrStartDatesAction,
  updateScenarioCtrStartDatesSuccessAction,
} from '@app/calendarization/store/ctrs-calendarization.actions';
import { ScenarioCtrStartDates } from '@app/models/backendModel';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-calendarization-ctr-startdate-edition',
  templateUrl: './calendarization-ctr-startdate-edition.component.html',
  styleUrls: ['./calendarization-ctr-startdate-edition.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class CalendarizationCtrStartdateEditionComponent
  implements OnDestroy, OnInit
{
  public updatedCtrsToEditFormArray = new FormArray([]);
  public scenarioId: number;
  private destroy$ = new Subject<void>();

  constructor(
    private selectedCtrsToEditService: CalendarizationCtrsToEditService,
    public dialogRef: MatDialogRef<CalendarizationCtrStartdateEditionComponent>,
    private store: Store,
    private datePipe: DatePipe,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.selectedCtrsToEditService.checkedCtrsToEdit$.subscribe(
      (selectedCtrs) => {
        this.scenarioId = selectedCtrs[0].scenarioId;
        selectedCtrs.forEach((ctr) => {
          this.updatedCtrsToEditFormArray.push(
            new FormGroup({
              scenarioCTRId: new FormControl(ctr.scenarioCTRId),
              startDate: new FormControl(ctr.startDate),
              ctrShortName: new FormControl(ctr.ctrShortName),
              duration: new FormControl(ctr.duration),
            })
          );
        });
      }
    );
  }

  ctrsModel(): ScenarioCtrStartDates[] {
    return this.updatedCtrsToEditFormArray.controls.map((group: FormGroup) => {
      const startDate = group.get('startDate').value;
      const formattedStartDate = this.datePipe.transform(
        startDate,
        'yyyy-MM-dd'
      );
      return {
        scenarioCtrId: group.get('scenarioCTRId').value,
        startDate: formattedStartDate,
      };
    });
  }

  UpdateScenarioCtrsStartDates(): void {
    const newCtrStartDates = this.ctrsModel();
    this.store.dispatch(
      updateScenarioCtrStartDatesAction({
        payload: {
          scenarioId: this.scenarioId,
          editedScenarioCtrStartDates: newCtrStartDates,
        },
      })
    );

    this.actions$
      .pipe(
        ofType(updateScenarioCtrStartDatesSuccessAction),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.store.dispatch(
          getCtrsCalendarizationAction({
            payload: { scenarioId: this.scenarioId },
          })
        );
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
