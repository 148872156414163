<h1
  mat-dialog-title
  data-test="activities-enter-edit-mode"
>Enter Edit mode?</h1>
<div
  mat-dialog-content
  data-test="activities-enter-edit-mode-content"
>
  <div>
    You will lock Edit mode and will be the only user to edit Activities during this time.
  </div>
  <div>
    You can close Edit mode at any time with or without applying your changes.
  </div>
  <div>
    After 5 minutes of inactivity, Edit mode will close automatically. Your changes will be saved and may be applied or not by the next person entering Edit mode.
  </div>
  <div class="last-paragraph">
    <strong>Any changes you do will not be applied until you click on “Review changes” button and confirm.</strong>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="false"
  >Cancel</button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
  >Ok</button>
</div>