import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { filter, first, switchMap, take } from 'rxjs/operators';

import {
  DisciplineDialogComponent,
  DisciplineDialogData,
} from '@admin/admin-pxd/dialogs/discipline-dialog/discipline-dialog.component';
import {
  ProductDialogComponent,
  ProductDialogData,
} from '@admin/admin-pxd/dialogs/product-dialog/product-dialog.component';
import {
  selectSegmentDisciplineByIdFactory,
  selectSegmentFactory,
  selectSegmentProductByIdFactory,
} from '@collections/pxds/store/pxds.selectors';
import {
  PxDOrderDialogComponent,
  PxDOrderDialogData,
} from '@common/pxd-order-dialog/pxd-order-dialog.component';
import { Discipline } from '@models/discipline';
import { Product } from '@models/product';

@Injectable({ providedIn: 'root' })
export class PxDsService {
  constructor(
    private dialog: MatDialog,
    private store: Store,
    private snackBar: MatSnackBar
  ) {}

  public openReorderPxDs(businessSegmentId: number) {
    return this.store.select(selectSegmentFactory(businessSegmentId)).pipe(
      first(),
      switchMap(({ disciplines, products }) => {
        return this.openDialog<PxDOrderDialogComponent, PxDOrderDialogData>(
          PxDOrderDialogComponent,
          {
            data: {
              actionLabel: 'Ok',
              disciplines: disciplines.map((discipline) => ({
                id: discipline.id,
                order: discipline.order,
                isActive: discipline.isActive,
                label: discipline.shortName,
              })),
              products: products.map((product) => ({
                id: product.id,
                order: product.order,
                isActive: product.isActive,
                label: product.shortName,
              })),
            },
          }
        )
          .afterClosed()
          .pipe(filter((v) => !!v));
      })
    );
  }

  public openCreateProductDialog(businessSegmentId: number) {
    return this.openDialog<ProductDialogComponent, ProductDialogData>(
      ProductDialogComponent,
      { data: { product: { businessSegmentId } as Product, actionName: 'Add' } }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  public openCreateDisciplineDialog(businessSegmentId: number) {
    return this.openDialog<DisciplineDialogComponent, DisciplineDialogData>(
      DisciplineDialogComponent,
      {
        data: {
          discipline: { businessSegmentId } as Discipline,
          actionName: 'Add',
        },
      }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  public openEditProductDialog(productId: number, businessSegmentId: number) {
    return this.store
      .select(selectSegmentProductByIdFactory(businessSegmentId, productId))
      .pipe(
        take(1),
        switchMap((product) =>
          this.openDialog<ProductDialogComponent, ProductDialogData>(
            ProductDialogComponent,
            {
              data: { product, actionName: 'Edit' },
            }
          )
            .afterClosed()
            .pipe(filter((v) => !!v))
        )
      );
  }

  public openEditDisciplineDialog(
    disciplineId: number,
    businessSegmentId: number
  ) {
    return this.store
      .select(
        selectSegmentDisciplineByIdFactory(businessSegmentId, disciplineId)
      )
      .pipe(
        take(1),
        switchMap((discipline) =>
          this.openDialog<DisciplineDialogComponent, DisciplineDialogData>(
            DisciplineDialogComponent,
            {
              data: { discipline, actionName: 'Edit' },
            }
          )
            .afterClosed()
            .pipe(filter((v) => !!v))
        )
      );
  }

  public showNotification(message: string, duration = 2000) {
    return this.snackBar.open(message, null, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  private openDialog<T, S>(dialog: ComponentType<T>, data: MatDialogConfig<S>) {
    data.maxHeight = '95vh';
    data.maxWidth = 'calc(min(90vw, 1200px))';
    const dialogRef = this.dialog.open<T, S>(dialog, data);

    return dialogRef;
  }
}
