<div class="dialog-container">
  <div mat-dialog-content>
    <h2><strong>CTR start date</strong></h2>
    <div
      *ngFor="let ctr of updatedCtrsToEditFormArray.controls; let i = index"
      class="ctrs-to-update"
    >
      <h3 data-test="edit-ctr-start-date-pxd-name">
        {{ ctr.get('ctrShortName').value }}
      </h3>
      <mat-form-field appearance="outline">
        <mat-label>Start date</mat-label>
        <input
          matInput
          [matDatepicker]="ctrStartDatePicker"
          autocomplete="off"
          [formControl]="ctr.get('startDate')"
          data-test="edit-ctr-start-date-input"
        />
        <mat-datepicker-toggle
          data-test="edit-ctr-start-date-picker"
          matSuffix
          [for]="ctrStartDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #ctrStartDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <div
      class="notification-note"
      data-test="notify-ctr-owners-about-date-change"
    >
      Warning: CTR Owners and Supporters will be notified about the CTR Start
      Date change.
    </div>
    <div class="buttons">
      <button mat-raised-button (click)="onCancel()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        (click)="UpdateScenarioCtrsStartDates()"
        data-test="save-edit-start-date-button"
      >
        Save
      </button>
    </div>
  </div>
</div>
