import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RolesSortDirection } from '@app/common/enums';
import {
  CtrFteDistributionForRoles,
  CtrHoursDistributionForRoles,
  FteDistributionForMatrix,
  FtePeriod,
  HoursDistributionForMatrix,
} from '@app/models/backendModel';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  pairwise,
  ReplaySubject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IntegerPipe } from '@app/common/pipe/integer.pipe';
import { OneDecimalPipe } from '@app/common/pipe/one-decimal.pipe';
import { environment } from '@environments/environment';
import { CalendarizationTableSharedModule } from '../calendarization-table-shared/calendarization-table-shared-module';
import {
  CalendarizationTableSharedService,
  YearData,
} from '../calendarization-table-shared/calendarization-table-shared.service';
import { CalendarizationViewType } from '../calendarization-table-shared/calendarization-table-total-in-month/calendarization-table-total-in-month.component';
import {
  DistributeWorkloadEvenlyDialogComponent,
  DistributeWorkloadEvenlyDialogData,
} from '../distribute-workload-evenly-dialog/distribute-workload-evenly-dialog.component';
import { RolesCalendarizationActions } from '../store/roles-calendarization.actions';
import {
  selectCtrCalendarizationData,
  selectCtrFteDistributionForRoles,
  selectCtrHoursDistributionForRoles,
  selectCurrentUserIsOwner,
} from '../store/roles-calendarization.selectors';
import { totalAmountIsCorrect } from './calendarization-table-roles.validator';
import { RolesSortButtonComponent } from './roles-sort-button/roles-sort-button.component';
export interface CtrWorkloadDates {
  years: YearData[];
}

export interface RolesSortConfiguration {
  byEntity: RolesSortDirection;
  byRoleType: RolesSortDirection;
  byRoleName: RolesSortDirection;
}
export interface CtrWorkloadDates {
  years: YearData[];
}
export interface RolesSortConfiguration {
  byEntity: RolesSortDirection;
  byRoleType: RolesSortDirection;
  byRoleName: RolesSortDirection;
}

export interface CtrHoursDistributionForRolesWithTotal
  extends CtrHoursDistributionForRoles {
  totalHours: number;
}

export interface FtePeriodFormGroup {
  fteInPeriod: FormControl<number>;
  isPeriodEditable: FormControl<boolean>;
  index: FormControl<number>;
}
export interface FteDistributionForMatrixFormGroup {
  index: FormControl<number>;
  ftePeriods: FormArray<FormGroup<FtePeriodFormGroup>>;
}
export interface CtrFteDistributionForRolesWithTotalFormGroup {
  expectedTotalFte: FormControl<number>;
  entityId: FormControl<number>;
  engineeringRoleId: FormControl<number>;
  entityName: FormControl<string>;
  roleName: FormControl<string>;
  roleType: FormControl<string>;
  fteDistribution: FormArray<FormGroup<FteDistributionForMatrixFormGroup>>;
}
@Component({
  selector: 'app-calendarization-table-roles',
  templateUrl: './calendarization-table-roles.component.html',
  styleUrls: ['./calendarization-table-roles.component.scss'],
  standalone: true,
  providers: [DecimalPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    RolesSortButtonComponent,
    CalendarizationTableSharedModule,
    IntegerPipe,
    OneDecimalPipe,
  ],
})
export class CalendarizationTableRolesComponent implements OnInit, OnDestroy {
  @Input() public set ctrId(value: number) {
    this.ctrId$.next(value);
  }
  @Input() public isRequestorMode = false;
  public readonly ctrId$ = new ReplaySubject<number>(1);
  public editModeEnabled = false;

  public readonly isEditCalendarizationFeatureEnabled =
    environment.isEditCalendarizationFeatureEnabled;
  private readonly componentDestroyed$ = new ReplaySubject<boolean>();

  public readonly userIsOwner$ = this.ctrId$.pipe(
    switchMap((ctrId) => this.store.select(selectCurrentUserIsOwner(ctrId)))
  );

  constructor(
    private readonly store: Store,
    private readonly calendarizationTableSharedService: CalendarizationTableSharedService,
    private readonly fb: FormBuilder,
    public readonly dialog: MatDialog
  ) {}
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public sortConfiguration$ = new BehaviorSubject<RolesSortConfiguration>({
    byEntity: RolesSortDirection.NO_SORT,
    byRoleType: RolesSortDirection.NO_SORT,
    byRoleName: RolesSortDirection.NO_SORT,
  });

  public entityFilter$ = new BehaviorSubject<string>('');
  public roleTypeFilter$ = new BehaviorSubject<string>('');
  public roleNameFilter$ = new BehaviorSubject<string>('');

  ngOnInit(): void {
    this.ctrId$
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap((ctrId) =>
          this.store.dispatch(
            RolesCalendarizationActions.initRolesCalendarization({
              ctrId: ctrId,
            })
          )
        )
      )
      .subscribe();
    this.fteDistributionsForRoles$.subscribe();
  }

  public timeRange$ = this.ctrId$.pipe(
    switchMap((x) => this.store.select(selectCtrCalendarizationData(x))),
    filter((data) => !!data),
    map((data) =>
      this.generateCtrWorkloadYearlyDates(
        data.startDate,
        data.endDate,
        data.ctrFteDistributionForRoles,
        data.ctrHoursDistributionForRoles
      )
    )
  );

  public hoursDistributionsForRoles$: Observable<
    CtrHoursDistributionForRolesWithTotal[]
  > = this.ctrId$.pipe(
    switchMap((x) => this.store.select(selectCtrHoursDistributionForRoles(x))),
    map((hoursDistributionForRoles) => {
      return hoursDistributionForRoles.map((roleDistribution) => {
        return {
          ...roleDistribution,
          totalHours:
            this.calendarizationTableSharedService.getTotalHoursForCtr(
              roleDistribution.hoursDistribution
            ),
          hoursDistribution:
            this.generateHoursDistributionBetweenCtrStartAndEndDates(
              roleDistribution.hoursDistribution
            ),
        };
      });
    })
  );

  public fteDistributionsForRoles$: Observable<CtrFteDistributionForRoles[]> =
    this.ctrId$.pipe(
      switchMap((x) => this.store.select(selectCtrFteDistributionForRoles(x))),
      takeUntil(this.componentDestroyed$),
      filter(() => !this.editModeEnabled),
      map((fteDistributionForRoles) => {
        return fteDistributionForRoles.map((roleDistribution) => {
          return {
            ...roleDistribution,
            fteDistribution:
              this.generateFteDistributionBetweenCtrStartAndEndDates(
                roleDistribution.fteDistribution
              ),
          };
        });
      })
    );

  crateForm(
    fteRolesDistribution: CtrFteDistributionForRoles[]
  ): FormArray<FormGroup<CtrFteDistributionForRolesWithTotalFormGroup>> {
    if (fteRolesDistribution.length == 0) {
      return null;
    }
    return this.fb.array(
      fteRolesDistribution.map((role) =>
        this.createCtrFteDistributionForRolesWithTotalFormGroup(role)
      )
    );
  }
  public createCtrFteDistributionForRolesWithTotalFormGroup(
    role: CtrFteDistributionForRoles
  ): FormGroup<CtrFteDistributionForRolesWithTotalFormGroup> {
    const formGroup: FormGroup<CtrFteDistributionForRolesWithTotalFormGroup> =
      this.fb.group<CtrFteDistributionForRolesWithTotalFormGroup>(
        {
          expectedTotalFte: this.fb.control({
            value: role.expectedTotalFte,
            disabled: true,
          }),
          entityId: this.fb.control({ value: role.entityId, disabled: true }),
          engineeringRoleId: this.fb.control({
            value: role.engineeringRoleId,
            disabled: true,
          }),
          entityName: this.fb.control({
            value: role.entityName,
            disabled: true,
          }),
          roleName: this.fb.control({ value: role.roleName, disabled: true }),
          roleType: this.fb.control({ value: role.roleType, disabled: true }),
          fteDistribution: this.fb.array(
            role.fteDistribution.map((year) =>
              this.createFteDistributionForMatrixFormGroup(year)
            )
          ),
        },
        { validators: totalAmountIsCorrect(role.expectedTotalFte) }
      );
    formGroup.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((x) =>
          this.getFteFromDistribution(
            x.fteDistribution as FteDistributionForMatrix[]
          )
        ),
        map((x) => JSON.stringify(x)),
        pairwise(),
        switchMap(([prev, curr]) => {
          const areEqual = prev === curr;

          return areEqual ? EMPTY : of(curr);
        })
      )
      .subscribe(() => {
        this.updateCtrsWorkloadsDistributions(formGroup);
      });
    return formGroup;
  }
  public getFteFromDistribution(
    distributions: FteDistributionForMatrix[]
  ): number[] {
    return distributions.reduce<number[]>((acc, distribution) => {
      distribution.ftePeriods.forEach((period) => {
        acc.push(period.fteInPeriod ?? 0);
      });
      return acc;
    }, []);
  }
  public createFteDistributionForMatrixFormGroup(
    distribution: FteDistributionForMatrix
  ): FormGroup<FteDistributionForMatrixFormGroup> {
    return this.fb.group<FteDistributionForMatrixFormGroup>({
      index: this.fb.control({ value: distribution.index, disabled: true }),
      ftePeriods: this.fb.array(
        distribution.ftePeriods.map((period) =>
          this.createFtePeriodFormGroup(period)
        )
      ),
    });
  }
  public readonly patternForFte = /^(\d*)$|^(\d*\.\d)$/;
  public createFtePeriodFormGroup(
    period: FtePeriod
  ): FormGroup<FtePeriodFormGroup> {
    return this.fb.group<FtePeriodFormGroup>({
      fteInPeriod: this.fb.control(period.fteInPeriod, [
        Validators.min(0),
        Validators.pattern(this.patternForFte),
      ]),
      index: this.fb.control({ value: period.index, disabled: true }),
      isPeriodEditable: this.fb.control({
        value: period.isPeriodEditable,
        disabled: true,
      }),
    });
  }
  public onSortChange(newSortConfig: RolesSortConfiguration): void {
    this.sortConfiguration$.next(newSortConfig);
  }

  public applyFilter(value: string, filterType: string): void {
    switch (filterType) {
      case 'entity':
        this.entityFilter$.next(value);
        break;
      case 'roleType':
        this.roleTypeFilter$.next(value);
        break;
      case 'roleName':
        this.roleNameFilter$.next(value);
        break;
    }
  }

  public filterData(
    data: (CtrFteDistributionForRoles | CtrHoursDistributionForRoles)[],
    entityFilter: string,
    roleTypeFilter: string,
    roleNameFilter: string
  ): (CtrFteDistributionForRoles | CtrHoursDistributionForRoles)[] {
    return data.filter((item) => {
      const matchesEntity = item.entityName
        .toLowerCase()
        .includes(entityFilter.toLowerCase());
      const matchesRoleType = item.roleType
        .toLowerCase()
        .includes(roleTypeFilter.toLowerCase());
      const matchesRoleName = item.roleName
        .toLowerCase()
        .includes(roleNameFilter.toLowerCase());
      return matchesEntity && matchesRoleType && matchesRoleName;
    });
  }

  public filteredAndSortedFteForRoles$: Observable<
    FormArray<FormGroup<CtrFteDistributionForRolesWithTotalFormGroup>>
  > = combineLatest([
    this.fteDistributionsForRoles$,
    this.sortConfiguration$,
    this.entityFilter$,
    this.roleTypeFilter$,
    this.roleNameFilter$,
  ]).pipe(
    map(([data, sortConfig, entityFilter, roleTypeFilter, roleNameFilter]) => {
      const filteredData = this.filterData(
        data,
        entityFilter,
        roleTypeFilter,
        roleNameFilter
      ) as CtrFteDistributionForRoles[];

      return this.getSortedData(
        filteredData,
        sortConfig
      ) as CtrFteDistributionForRoles[];
    }),
    map((x) => this.crateForm(x))
  );

  public filteredAndSortedHoursForRoles$: Observable<
    CtrHoursDistributionForRoles[]
  > = combineLatest([
    this.hoursDistributionsForRoles$,
    this.sortConfiguration$,
    this.entityFilter$,
    this.roleTypeFilter$,
    this.roleNameFilter$,
  ]).pipe(
    map(([data, sortConfig, entityFilter, roleTypeFilter, roleNameFilter]) => {
      const filteredData = this.filterData(
        data,
        entityFilter,
        roleTypeFilter,
        roleNameFilter
      ) as CtrHoursDistributionForRoles[];

      return this.getSortedData(
        filteredData,
        sortConfig
      ) as CtrHoursDistributionForRoles[];
    })
  );

  public getSortedData(
    data: CtrFteDistributionForRoles[] | CtrHoursDistributionForRoles[],
    sortConfig: RolesSortConfiguration
  ): CtrFteDistributionForRoles[] | CtrHoursDistributionForRoles[] {
    if (sortConfig.byEntity !== RolesSortDirection.NO_SORT) {
      data.sort((a, b) => {
        if (sortConfig.byEntity === RolesSortDirection.ASC) {
          return a.entityName.localeCompare(b.entityName);
        } else {
          return b.entityName.localeCompare(a.entityName);
        }
      });
    } else if (sortConfig.byRoleType !== RolesSortDirection.NO_SORT) {
      data.sort((a, b) => {
        if (sortConfig.byRoleType === RolesSortDirection.ASC) {
          return a.roleType.localeCompare(b.roleType);
        } else {
          return b.roleType.localeCompare(a.roleType);
        }
      });
    } else if (sortConfig.byRoleName !== RolesSortDirection.NO_SORT) {
      data.sort((a, b) => {
        if (sortConfig.byRoleName === RolesSortDirection.ASC) {
          return a.roleName.localeCompare(b.roleName);
        } else {
          return b.roleName.localeCompare(a.roleName);
        }
      });
    }

    return data;
  }

  public selectedView$: BehaviorSubject<CalendarizationViewType> =
    new BehaviorSubject<CalendarizationViewType>(CalendarizationViewType.FTE);
  public chartViewType = CalendarizationViewType;

  public getTitleUnits$: Observable<string> = this.selectedView$
    .asObservable()
    .pipe(
      map((view) => (view === CalendarizationViewType.FTE ? 'FTEs' : 'Hours'))
    );

  public selectedViewChanged(newView: CalendarizationViewType): void {
    this.selectedView$.next(newView);
  }
  public totalAmounts$ = this.timeRange$.pipe(
    map((timeRange) => {
      return timeRange.years.reduce(
        (acc, curr) => {
          curr.months.forEach((month) => {
            acc.fte += month.totalFte;
            acc.hours += month.totalHours;
          });
          return acc;
        },
        { fte: 0, hours: 0 }
      );
    })
  );
  public generateCtrWorkloadYearlyDates(
    startDate: Date,
    endDate: Date,
    fteDistribution: CtrFteDistributionForRoles[],
    hoursDistribution: CtrHoursDistributionForRoles[]
  ): CtrWorkloadDates {
    const fte = fteDistribution.reduce<FteDistributionForMatrix[]>(
      (acc, curr) => {
        curr.fteDistribution.forEach((year) => {
          acc = acc.concat(year);
        });
        return acc;
      },
      []
    );
    const hours = hoursDistribution.reduce<HoursDistributionForMatrix[]>(
      (acc, curr) => {
        curr.hoursDistribution.forEach((year) => {
          acc = acc.concat(year);
        });
        return acc;
      },
      []
    );
    const years: YearData[] =
      this.calendarizationTableSharedService.getYearsMonthsSplitWithTotals(
        startDate,
        endDate,
        false,
        hours,
        fte
      );

    return { years };
  }

  public generateHoursDistributionBetweenCtrStartAndEndDates(
    hoursDistribution: HoursDistributionForMatrix[]
  ): HoursDistributionForMatrix[] {
    return hoursDistribution.map((distribution) => {
      const filteredHoursPeriods = distribution.hoursPeriods.filter(
        (period) => period.isPeriodEditable
      );

      return {
        ...distribution,
        hoursPeriods: filteredHoursPeriods,
      };
    });
  }

  public generateFteDistributionBetweenCtrStartAndEndDates(
    fteDistribution: FteDistributionForMatrix[]
  ): FteDistributionForMatrix[] {
    return fteDistribution.map((distribution) => {
      const filteredFtesPeriods = distribution.ftePeriods.filter(
        (period) => period.isPeriodEditable
      );

      return {
        ...distribution,
        ftePeriods: filteredFtesPeriods,
      };
    });
  }

  public clearFilters(
    entityInput: HTMLInputElement,
    roleTypeInput: HTMLInputElement,
    roleNameInput: HTMLInputElement
  ): void {
    this.entityFilter$.next('');
    this.roleTypeFilter$.next('');
    this.roleNameFilter$.next('');

    entityInput.value = '';
    roleTypeInput.value = '';
    roleNameInput.value = '';
  }

  public changeEditMode(): void {
    this.editModeEnabled = !this.editModeEnabled;
  }

  public updateCtrsWorkloadsDistributions(
    role: FormGroup<CtrFteDistributionForRolesWithTotalFormGroup>
  ): void {
    if (role.invalid) {
      return;
    }
    const rawValue = role.getRawValue();

    this.ctrId$
      .pipe(
        take(1),
        map((ctrId) =>
          this.store.dispatch(
            RolesCalendarizationActions.updateRolesCalendarization({
              scenarioCTRId: ctrId,
              engineeringRoleId: rawValue.engineeringRoleId,
              entityId: rawValue.entityId,
              fteDistribution: rawValue.fteDistribution.map((year) => ({
                ...year,
                ftePeriods: year.ftePeriods.map((period) => ({
                  ...period,
                  fteInPeriod: period.fteInPeriod ?? 0,
                })),
              })),
            })
          )
        )
      )
      .subscribe();
  }
  public distributeEvenly(): void {
    this.ctrId$
      .pipe(
        take(1),
        map((scenarioCtrId) =>
          this.dialog
            .open(DistributeWorkloadEvenlyDialogComponent, {
              data: {
                scenarioCtrId: scenarioCtrId,
              } as DistributeWorkloadEvenlyDialogData,
            })
            .afterClosed()
        )
      )
      .subscribe();
  }
}
