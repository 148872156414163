<ng-container *ngIf="tableService.selectedPxDBlocks$ | async as blocks">
  <mat-card appearance="outlined">
    <mat-card-title
      class="block-filters-header"
      data-test="admin-activities-pxd-header"
    >
      {{ pxd.shortName }} : Blocks
      <div class="actions">
        <div
          class="disabled-button-tooltip-trigger"
          [matTooltipDisabled]="blocks.length > 1"
          matTooltip="Not enough blocks to reorder them"
        >
          <button
            class="order-blocks"
            color="primary"
            mat-stroked-button
            [disabled]="(tableService.readOnly$ | async) || blocks.length < 2"
            (click)="openReorderBlocks()"
            data-test="admin-activities-reorder-block-button"
          >
            <mat-icon>reorder</mat-icon>Reorder
          </button>
        </div>
        <button
          class="add-block"
          color="primary"
          mat-stroked-button
          [disabled]="tableService.readOnly$ | async"
          (click)="openAddBlock()"
          data-test="admin-activities-add-block-button"
        >
          <mat-icon>add</mat-icon>Block
        </button>
      </div>
    </mat-card-title>
    <ng-container *ngIf="blocks.length > 0; else emptyPxd">
      <mat-checkbox
        class="block-filter filter-all"
        [checked]="(tableService.filter$ | async).length === blocks.length"
        (change)="tableService.toggleAllBlocks($event)"
        data-test="show-all-blocks-checkbox"
        >Show all</mat-checkbox
      >
      <div class="block-filters">
        <mat-checkbox
          class="block-filter"
          data-test="show-block-checkbox"
          [class.inactive]="!block.isActive"
          *ngFor="let block of blocks; trackBy: trackByBlock"
          [checked]="(tableService.filter$ | async).includes(block.blockId)"
          (change)="tableService.toggleBlock(block.blockId, $event)"
          ><span class="block-filter-index">{{ block.order }}.</span
          >{{ block.blockDisplayName }}</mat-checkbox
        >
      </div>
    </ng-container>
    <ng-template #emptyPxd data-test="admin-activities-no-blocks-info">
      There are no blocks in this PxD.
    </ng-template>
  </mat-card>

  <ng-container
    *ngIf="tableService.filteredBlocksResults$ | async as filteredBlocksResults"
  >
    <h2 class="list-title">
      Result:
      {{ filteredBlocksResults.blocksCount | i18nPlural : blocksLabelMap
      }}<ng-container *ngIf="tableService.showScopes$ | async"
        >,
        {{
          filteredBlocksResults.scopesCount | i18nPlural : scopesLabelMap
        }}</ng-container
      >
      <ng-container *ngIf="tableService.showActivities$ | async"
        >,
        {{
          filteredBlocksResults.activitiesCount
            | i18nPlural : activitiesLabelMap
        }}</ng-container
      >
    </h2>

    <div class="toolbar">
      <mat-slide-toggle
        [ngModel]="tableService.showScopes$ | async"
        (ngModelChange)="tableService.showScopes$.next($event)"
        data-test="show-scopes-toggle"
        >Show scopes</mat-slide-toggle
      >
      <mat-slide-toggle
        *ngIf="tableService.showScopes$ | async"
        [ngModel]="tableService.showActivities$ | async"
        (ngModelChange)="tableService.showActivities$.next($event)"
        data-test="show-activities-toggle"
        >Show activities</mat-slide-toggle
      >
    </div>

    <div class="scroll-content">
      <app-masterdata-block
        [class.display-activities-level]="tableService.showActivities$ | async"
        *ngFor="
          let block of tableService.filteredBlocks$ | async;
          trackBy: trackByBlock
        "
        [data]="block"
      ></app-masterdata-block>
    </div>
  </ng-container>
</ng-container>
