<h2
  mat-dialog-title
  data-test="change-log-dialog-title"
>{{ data.title }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="changesOwner$ | async as changesOwner">
    <p>Changes made by <a
        *ngIf="changesOwner.email; else plainName"
        href="mailTo:{{ changesOwner.email }}"
        data-test="change-log-owner-label"
      >{{ changesOwner.userName }}</a> on {{ changesDate | date: 'MMMM d, yyyy \'at\' HH:mm' }}. <ng-container *ngIf="changesOwner.userName !== 'You'">If you decide to apply these changes, they will be attributed to you.</ng-container>
    </p>
    <ng-template #plainName><span data-test="change-log-owner-label">{{ changesOwner.userName }}</span></ng-template>
  </ng-container>

  <app-change-log-table
    [changesList]="changesList"
    [readonly]="readonly"
    (close)="close()"
  ></app-change-log-table>
</mat-dialog-content>
<div
  class="button-right"
  mat-dialog-actions
>
  <mat-checkbox
    [formControl]="notifyAboutChangesFormControl"
    *ngIf="showNotifyUsersCheckbox && changesList.length > 0"
    class="notify-users"
    color="primary"
    data-test="change-log-dialog-notify-users-checkbox"
    matTooltip="By an e-mail notification"
  >Notify users about changes</mat-checkbox>
  <button
    [attr.data-test]="'change-log-dialog-action-button-' + index"
    [mat-dialog-close]="{ action: button.action, notifyUsers: notifyAboutChangesFormControl.value }"
    [ngClass]="button.class"
    *ngFor="let button of buttons; let index = index"
    color="primary"
    mat-button
  >{{ button.label }}</button>
</div>