import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, map, shareReplay, take } from 'rxjs/operators';

import { selectMainAccountIsAdmin } from '@collections/users/store/users.selectors';

@Injectable()
export class MainUserIsAdminGuard implements CanActivate {
  private mainUserIsAdmin$ = this.store
    .select(selectMainAccountIsAdmin)
    .pipe(debounceTime(1000), shareReplay(1));

  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.mainUserIsAdmin$.pipe(
      take(1),
      map((mainUserIsAdmin) => {
        if (mainUserIsAdmin) {
          return true;
        }

        return this.router.parseUrl('/unauthorized');
      })
    );
  }
}
