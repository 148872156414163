<mat-card appearance="outlined" *ngIf="timeRange$ | async as timeRange">
  <div class="calendarization-title-header" *ngIf="timeRange.length">
    <div class="title-section">
      <mat-card-title
        data-test="calendarization-cost-over-time-label"
        class="cost-over-time-label"
        >Cost over time: {{ getTitleUnits() }}</mat-card-title
      >
      <button
        mat-button
        color="primary"
        class="info-btn"
        matTooltipClass="multiline-tooltip"
        matTooltipPosition="below"
        [matTooltip]="getCalendarizationDescription()"
        data-test="calendarization-tooltip"
      >
        <mat-icon>info_outline</mat-icon>
      </button>
    </div>
    <div class="action-buttons-section" *ngIf="timeRange.length">
      <mat-button-toggle-group
        class="mat-button-toggle-group"
        (change)="selectedViewChanged($event.value)"
        [value]="selectedView"
      >
        <mat-button-toggle
          [value]="chartViewType.MATRIX"
          matTooltip="Switch to matrix view"
          data-test="calendarization-switch-to-matrix-view-button"
          [disabled]="editModeEnabled"
        >
          <mat-icon>schedule</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="chartViewType.CHART"
          color="primary"
          matTooltip="Switch to chart view"
          data-test="calendarization-switch-to-chart-view-button"
          [disabled]="editModeEnabled"
        >
          <mat-icon class="rotated-bar-chart">bar_chart</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="chartViewType.FTE"
          data-test="calendarization-switch-to-fte-view-button"
          [disabled]="editModeEnabled"
          class="fte-toggle-button"
          [matTooltip]="'Switch to FTE view'"
        >
          FTE
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div
        class="vertical-line-separator"
        *ngIf="isRequestorMode && false"
      ></div>

      <button
        *ngIf="false"
        class="actions-button"
        mat-stroked-button
        color="primary"
        data-test="edit-ctr-fte-button"
        [disabled]="selectedView !== chartViewType.FTE"
        (click)="switchToEditMode()"
        matTooltip="Edit FTEs"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        class="actions-button image-color"
        mat-stroked-button
        color="primary"
        *ngIf="isRequestorMode"
        [matTooltip]="
          isAnyCtrCheckboxSelected
            ? 'Set CTR start date'
            : 'Setting start date available only if any CTR is selected'
        "
        [disabled]="!isAnyCtrCheckboxSelected"
        data-test="set-ctr-start-date-button"
        (click)="openCtrStartDateEditDialog()"
      >
        <mat-icon svgIcon="calendar-month"></mat-icon>
      </button>

      <div>
        <div class="vertical-line-separator"></div>
        <button
          class="actions-button"
          mat-stroked-button
          color="primary"
          data-test="show-detailed-info-button"
          [disabled]="!isOnlyOneCtrCheckboxSelected"
          [matTooltip]="
            isOnlyOneCtrCheckboxSelected
              ? 'Show detailed info'
              : 'Detailed info available only for a single CTR'
          "
          (click)="openCtrDetailedInfoDialog()"
        >
          <mat-icon>description</mat-icon>
        </button>
        <ng-container *ngIf="isRequestorMode">
          <button
            class="actions-button image-color"
            mat-stroked-button
            color="primary"
            [disabled]="!isOnlyOneCtrCheckboxSelected"
            data-test="export-selected-ctr-to-xls-button"
            [matTooltip]="
              isOnlyOneCtrCheckboxSelected
                ? 'Export to Excel'
                : 'Available only for a single CTR'
            "
            (click)="exportSelectedCtrToXls()"
          >
            <mat-icon svgIcon="xls-icon"></mat-icon>
          </button>

          <button
            class="actions-button image-color"
            mat-stroked-button
            color="primary"
            [disabled]="!isAnyCtrCheckboxSelected"
            data-test="export-selected-ctr-to-client-xls-button"
            [matTooltip]="
              isAnyCtrCheckboxSelected
                ? 'Export to Client Excel'
                : 'Available only if any CTR is selected'
            "
            (click)="exportSelectedCtrToClientExcel()"
          >
            <mat-icon svgIcon="xls-client-icon"></mat-icon>
          </button>
        </ng-container>
      </div>
      <div>
        <div class="vertical-line-separator" *ngIf="isRequestorMode"></div>
        <button
          class="show-ctr-owners-button inactive-owners-button actions-button"
          matTooltip="Show CTR owners"
          *ngIf="!showCtrOwner"
          mat-stroked-button
          (click)="showCtrOwner = !showCtrOwner"
          data-test="calendarization-show-ctr-owners-button"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button
          class="hide-ctr-owners-button actions-button"
          data-test="calendarization-hide-ctr-owners-button"
          matTooltip="Hide CTR owners"
          *ngIf="showCtrOwner"
          color="primary"
          mat-raised-button
          (click)="showCtrOwner = !showCtrOwner"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-card-content>
    <div class="table-scroll-wrapper" *ngIf="ctrs$ | async as ctrs">
      <table
        *ngIf="ctrs.length > 0"
        class="calendarization"
        aria-label="calendarization"
        data-test="calendarization-table"
      >
        <thead class="sticky-top">
          <tr>
            <th
              rowspan="2"
              scope="col"
              class="name nowrap sticky-left cell-hover"
              (click)="toggleSelectAll()"
            >
              <mat-checkbox
                class="ctr-checkbox"
                (change)="selectAllChanged($event.checked)"
                (click)="$event.stopPropagation()"
                [checked]="areAllCtrCheckboxesSelected"
                [indeterminate]="
                  !areAllCtrCheckboxesSelected && isAnyCtrCheckboxSelected
                "
              >
              </mat-checkbox>
              Product x Discipline
            </th>
            <th
              *ngFor="let year of timeRange"
              [attr.colspan]="year.months.length"
              class="time-unit"
              scope="col"
            >
              <span class="sticky-year">{{ year.yearIndex }}</span>
            </th>
            <th rowspan="2" class="sticky-right" scope="col">Total</th>
          </tr>
          <tr>
            <ng-container *ngFor="let year of timeRange">
              <th *ngFor="let month of year.months" class="time-unit">
                {{ month.monthShortName }}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="selectedView === chartViewType.CHART">
            <tr *ngFor="let ctr of ctrsDataForBarsView$ | async">
              <td
                class="name nowrap sticky-left cell-hover"
                (click)="toggleCheckbox(ctr.scenarioCTRId)"
              >
                <div class="ctr-name-checkbox">
                  <mat-checkbox
                    class="ctr-checkbox"
                    [(ngModel)]="checkboxStates[ctr.scenarioCTRId]"
                    (click)="$event.stopPropagation()"
                    (ngModelChange)="isAnyCtrChecked()"
                    data-test="ctr-pxd-checkbox"
                  >
                  </mat-checkbox>
                  <span data-test="pxd-name-label">{{ ctr.ctrShortName }}</span>
                  <span
                    *ngIf="showCtrOwner"
                    class="owner"
                    data-test="ctr-owner-label"
                    >{{ ctr.owner }}
                  </span>
                </div>
              </td>
              <ng-container
                *ngFor="let year of timeRange; let timeIndex = index"
              >
                <td
                  *ngFor="let month of year.months"
                  class="time-unit"
                  [class.bar-root]="timeIndex === 0 && month.monthIndex === 0"
                >
                  <div
                    *ngIf="timeIndex === 0 && month.monthIndex === 0"
                    class="bar"
                    [style]="ctr.style"
                    (click)="showTooltip($event, ctr)"
                    data-test="ctr-period-bar"
                  ></div>
                </td>
              </ng-container>
              <td class="total sticky-right">
                {{ ctr.totalHours | integer : true }}
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="selectedView === chartViewType.MATRIX">
            <tr *ngFor="let ctr of ctrsDataForMatrixView$ | async">
              <td
                class="name nowrap sticky-left ctrs-col-matrix cell-hover"
                (click)="toggleCheckbox(ctr.scenarioCTRId)"
              >
                <div class="ctrs-col-matrix-left ctr-name-checkbox">
                  <mat-checkbox
                    class="ctr-checkbox"
                    [(ngModel)]="checkboxStates[ctr.scenarioCTRId]"
                    (click)="$event.stopPropagation()"
                    (ngModelChange)="isAnyCtrChecked()"
                  >
                  </mat-checkbox>
                  {{ ctr.ctrShortName }}
                  <span *ngIf="showCtrOwner" class="owner"
                    >{{ ctr.owner }}
                  </span>
                </div>
              </td>
              <ng-container *ngFor="let year of ctr.years">
                <td
                  style="text-align: right"
                  class="hours-input-cells"
                  *ngFor="let period of year.hoursPeriods"
                >
                  {{ period.hoursInPeriod | integer : period.isPeriodEditable }}
                </td>
              </ng-container>
              <td class="total sticky-right">
                {{ ctr.totalNumberOfHours | integer : true }}
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="selectedView === chartViewType.FTE">
            <tr *ngFor="let ctr of ctrsDataForFTEView$ | async">
              <td
                class="name nowrap sticky-left ctrs-col-matrix cell-hover"
                (click)="toggleCheckbox(ctr.scenarioCTRId)"
              >
                <div class="ctrs-col-matrix-left ctr-name-checkbox">
                  <mat-checkbox
                    class="ctr-checkbox"
                    [(ngModel)]="checkboxStates[ctr.scenarioCTRId]"
                    (click)="$event.stopPropagation()"
                    (ngModelChange)="isAnyCtrChecked()"
                  >
                  </mat-checkbox>
                  {{ ctr.ctrShortName }}
                  <span *ngIf="showCtrOwner" class="owner"
                    >{{ ctr.owner }}
                  </span>
                </div>
              </td>
              <ng-container *ngFor="let year of ctr.years">
                <td
                  style="text-align: right"
                  class="hours-input-cells"
                  *ngFor="let period of year.ftePeriods"
                >
                  {{
                    period.fteInPeriod | oneDecimal : period.isPeriodEditable
                  }}
                </td>
              </ng-container>
              <td class="total sticky-right">
                {{ ctr.totalNumberOfFte | oneDecimal : true }}
              </td>
            </tr>
          </ng-container>
        </tbody>

        <tfoot class="sticky-bottom">
          <tr>
            <th class="sticky-left" scope="col">Total</th>
            <ng-container *ngFor="let year of timeRange">
              <th *ngFor="let month of year.months" class="total">
                <app-calendarization-table-total-in-month
                  [selectedView]="selectedView"
                  [month]="month"
                ></app-calendarization-table-total-in-month>
              </th>
            </ng-container>
            <th class="sticky-right"></th>
          </tr>
        </tfoot>
      </table>
      <ng-container *ngIf="ctrs.length === 0">
        <h4 class="no-ctrs-message" data-test="calendarization-no-data">
          <mat-icon>info</mat-icon>
          You need at least one active CTR to see calendarization data.
        </h4>
      </ng-container>
    </div>
    <h4 *ngIf="selectedView === chartViewType.FTE">
      Note: FTE is calculated based on 147 hours per month.
    </h4>
  </mat-card-content>
</mat-card>
