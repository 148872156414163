import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  timeout,
} from 'rxjs/operators';

import {
  identifyCurrentUserAction,
  identifyCurrentUserSuccessAction,
  setMsalUserAction,
  UsersActions,
} from '@collections/users/store/users.actions';
import {
  LoadingStateEnum,
  FEATURE_KEY as USERS_FEATURE_KEY,
} from '@collections/users/store/users.reducer';
import {
  selectCurrentUser,
  selectMsalUser,
  selectUserByIdFactory,
  selectUsersListLoadedState,
} from '@collections/users/store/users.selectors';
import { environment } from '@environments/environment';
import { IGetUsersResponse } from '@models/users';

import { UserDto } from '@app/models/backendModel';
import { CurrentUserIsAdminGuard } from './current-user-is-admin.guard';
import { MainUserIsAdminGuard } from './main-user-is-admin.guard';
import { UserIsEngineerGuard } from './user-is-engineer.guard';
import { UserIsRequestorGuard } from './user-is-requestor.guard';

const usersRestoredState =
  JSON.parse(localStorage.getItem(USERS_FEATURE_KEY)) || {};

if (!usersRestoredState.currentUserId && !environment.authentication) {
  usersRestoredState.currentUserId = environment.defaultUserId;
  usersRestoredState.mainUserId = environment.defaultUserId;
}

export function initializeUserData(msalService: MsalService, store: Store) {
  return (): Observable<boolean | void> => {
    // E2E
    if (!environment.authentication) {
      return msalService.handleRedirectObservable().pipe(
        switchMap((result) => {
          const e2eAccount: any = {};
          msalService.instance.setActiveAccount(e2eAccount);

          store.dispatch(UsersActions.getActiveUsersQuery());

          return store
            .select(selectUserByIdFactory(usersRestoredState.mainUserId))
            .pipe(
              filter((v) => !!v),
              take(1),
              timeout(3000),
              switchMap((user) => {
                store.dispatch(
                  identifyCurrentUserSuccessAction({
                    context: 'initializeUserData::recoverMainUser',
                    payload: { user, ready: true },
                  })
                );
                return store.select(
                  selectUserByIdFactory(usersRestoredState.currentUserId)
                );
              }),
              switchMap((user) => {
                store.dispatch(
                  identifyCurrentUserSuccessAction({
                    context: 'initializeUserData::recoverCurrentUser',
                    payload: { user, ready: true },
                  })
                );
                return of(true);
              }),
              take(1)
            );
        }),
        catchError((err) => {
          console.error('E2E: Redirect on authentication error', err);
          return of(true);
        })
      );
    }

    return msalService.handleRedirectObservable().pipe(
      switchMap((result) => {
        const activeAccount = !result
          ? msalService.instance.getActiveAccount()
          : result.account;
        const expiration = activeAccount?.idTokenClaims?.exp ?? 0;
        const now = Math.floor(Date.now() / 1000);
        const expiresIn = expiration - now;
        if (!activeAccount || expiresIn < 10) {
          return msalService.loginRedirect();
        }
        msalService.instance.setActiveAccount(activeAccount);
        const [firstName, lastName] = activeAccount.name.split(' ');
        store.dispatch(
          setMsalUserAction({
            context: 'initializeUserData',
            payload: {
              user: {
                userId: null,
                entityId: null,
                businessSegmentId: 1,
                isAdmin: false,
                isRequestor: false,
                isEngineer: false,
                firstName,
                lastName,
                email: activeAccount.username,
              } as IGetUsersResponse,
            },
          })
        );
        store.dispatch(UsersActions.getActiveUsersQuery());
        if (!usersRestoredState.mainUserId) {
          const [firstName, lastName] = activeAccount?.name.split(' ') || '';

          store.dispatch(
            identifyCurrentUserAction({
              context: 'initializeUserData::getAuthorizedUser',
              payload: {
                msalUser: {
                  firstName,
                  lastName,
                  email: activeAccount.username,
                },
              },
            })
          );

          return store.select(selectUsersListLoadedState).pipe(
            filter(
              (x) =>
                [LoadingStateEnum.LOADED, LoadingStateEnum.ERROR].indexOf(x) >
                -1
            ),
            switchMap((loadingState) => {
              if (loadingState === LoadingStateEnum.LOADED) {
                return store.select(selectCurrentUser);
              } else {
                console.warn(
                  "User is not eCTR user that's why he do not have to getUsers API endpoint."
                );
                console.warn(
                  'Returning here MsalUser as current user to make registration page working'
                );
                return store.select(selectMsalUser);
              }
            }),
            filter((v) => !!v),
            debounceTime(1000),
            map((user: UserDto) => {
              if (user.userId) {
                store.dispatch(
                  identifyCurrentUserSuccessAction({
                    context: 'initializeUserData::recoverMainUser',
                    payload: { user: user, ready: true },
                  })
                );
              } else {
                console.warn('User is not application user', user);
              }
              return true;
            })
          );
        }
        return store
          .select(selectUserByIdFactory(usersRestoredState.mainUserId))
          .pipe(
            filter((v) => !!v),
            take(1),
            switchMap((mainUser) => {
              store.dispatch(
                identifyCurrentUserSuccessAction({
                  context: 'initializeUserData::recoverMainUser',
                  payload: { user: mainUser, ready: true },
                })
              );

              if (!mainUser.isAdmin) {
                return of(true);
              }
              // if restored user doesn't match cached impersonated user
              // try recover impersonated user data
              return store
                .select(selectUserByIdFactory(usersRestoredState.currentUserId))
                .pipe(
                  filter((v) => !!v),
                  map((impersonatedUser) => {
                    store.dispatch(
                      identifyCurrentUserSuccessAction({
                        context: 'initializeUserData::recoverImpersonatedUser',
                        payload: { user: impersonatedUser, ready: true },
                      })
                    );
                    return true;
                  })
                );
            })
          );
      }),
      catchError((err) => {
        console.error('Redirect on authentication error', err);
        return msalService.loginRedirect();
      }),
      take(1)
    );
  };
}

export const correlationId = format(new Date(), 'HH:mm:ss:SSS');

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  console[['error', 'warn', 'info', 'debug', 'trace'][logLevel % 5]](
    `%cMSAL`,
    ['color: #f40', 'color: #f84', 'color: #48f', 'color: #8af', 'color: #8af'][
      logLevel % 5
    ],
    message
  );
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    // MSAL Configuration
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/${environment.tenantId}/`,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
      secureCookies: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        correlationId,
        piiLoggingEnabled: false,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Info,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    [
      'https://ectr-api.dev.services.technipfmc.com/api/',
      [`${environment.clientId}/User.Read`],
    ],
    [
      'https://ectr-api.demo.services.technipfmc.com/api/',
      [`${environment.clientId}/User.Read`],
    ],
    [
      'https://ectr-api.services.technipfmc.com/api/',
      [`${environment.clientId}/User.Read`],
    ],
    ['https://localhost:5080/api/', [`${environment.clientId}/User.Read`]],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', `${environment.clientId}/api-access`],
    },
  };
}

@NgModule({
  imports: [MsalModule],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initializeUserData,
          deps: [MsalService, Store],
          multi: true,
        },
        environment.authentication
          ? {
              provide: HTTP_INTERCEPTORS,
              useClass: MsalInterceptor,
              multi: true,
            }
          : [],
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        UserIsRequestorGuard,
        UserIsEngineerGuard,
        CurrentUserIsAdminGuard,
        MainUserIsAdminGuard,
      ],
    };
  }
}
