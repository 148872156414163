<ng-template #headerPortalContent>
  <div
    *ngIf="lockedBy$ | async as lockedBy"
    class="lock-indicator-header"
    [ngSwitch]="lockOwnerState$ | async"
    data-test="lock-indicator-header"
  >
    {{ lockedBy.prefixLabel }}
    <ng-container *ngIf="lockedBy.userEmail; else onlyName">
      <a
        href="mailTo:{{ lockedBy.userEmail }}"
        matTooltip="Email to {{ lockedBy.userEmail }}"
      >{{ lockedBy.userName }}</a>
    </ng-container>
    <ng-template #onlyName>{{ lockedBy.userName }}</ng-template>
    <ng-container *ngIf="lockedBy.suffixLabel"> {{ lockedBy.suffixLabel }}</ng-container>
    <mat-icon
      class="info-icon"
      (click)="openInfoDialog()"
    >info_outlined</mat-icon>
    <button
      class="lock-edit small"
      [class.locked]="(lockOwnerState$ | async) === LockOwnerState.OWNER"
      [disabled]="(lockOwnerState$ | async) === LockOwnerState.LOCKED"
      mat-stroked-button
      (click)="showLockModal()"
      data-test="lock-indicator-header-toggle-button"
    >
      <ng-container *ngSwitchCase="LockOwnerState.LOCKED">
        <mat-icon>lock</mat-icon>
        Edit mode
      </ng-container>
      <ng-container *ngSwitchCase="LockOwnerState.UNLOCKED">
        <mat-icon>lock</mat-icon>
        Edit mode
      </ng-container>
      <ng-container *ngSwitchCase="LockOwnerState.EXPIRED">
        <mat-icon>lock</mat-icon>
        Edit mode
      </ng-container>
      <ng-container *ngSwitchCase="LockOwnerState.OWNER">
        <mat-icon>lock_open</mat-icon>
        Review changes
      </ng-container>
    </button>
  </div>
</ng-template>

<div
  *ngIf="(lockOwnerState$ | async) === LockOwnerState.OWNER"
  class="lock-bar"
>{{ lockExpirationCountdown$ | async }}</div>