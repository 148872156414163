import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Subscription,
  combineLatest,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs';

import { selectSidenavScenarios } from '@app/layout/sidenav/store/sidenav.selectors';
import { postCommentAction } from '@collections/event-log/store/event-log.actions';
import { getScenarioAction } from '@collections/scenarios/store/scenarios.actions';
import { selectScenarioCTRsFactory } from '@collections/scenarios/store/scenarios.selectors';
import {
  selectCurrentRouteParams,
  selectEventLogContext,
} from '@core/store/core.selectors';
import { shareReplay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnChanges {
  constructor(private store: Store) {}

  @Input() public projectId = null;

  @Input() public scenarioId = null;

  @Input() public ctrId = null;

  public showOptions = false;

  public commentFormGroup = new FormGroup({
    scenarioId: new FormControl(),
    scenarioCTRId: new FormControl(),
    message: new FormControl(),
  });

  public scenarios$ = this.store.select(selectSidenavScenarios);

  public ctrs$ = this.commentFormGroup.get('scenarioId').valueChanges.pipe(
    startWith(this.commentFormGroup.get('scenarioId').value),
    distinctUntilChanged(),
    switchMap((scenarioId) => {
      if (scenarioId) {
        this.store.dispatch(
          getScenarioAction({
            context: 'FilterComponent::ctrs$',
            payload: { scenarioId },
          })
        );
      }

      return this.store.select(selectScenarioCTRsFactory(scenarioId));
    }),
    tap((ctrs) => {
      const ctrId = ctrs.length > 0 ? ctrs[0].scenarioCTRId : null;
      this.ctrId = ctrId;
      this.commentFormGroup.patchValue(
        {
          scenarioCTRId: ctrId,
        },
        { onlySelf: false, emitEvent: true }
      );
    }),
    shareReplay(1)
  );

  private subscription = new Subscription();

  private eventLogContext$ = this.store.select(selectEventLogContext);

  public ngOnInit(): void {
    this.subscription.add(
      this.commentFormGroup
        .get('scenarioId')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe(() => {
          this.commentFormGroup.get('scenarioCTRId').setValue(null);
        })
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.commentFormGroup.patchValue(
      {
        scenarioId: this.scenarioId || null,
        scenarioCTRId: this.ctrId || null,
      },
      { onlySelf: false, emitEvent: true }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public sendComment() {
    this.subscription.add(
      combineLatest([
        this.store.select(selectCurrentRouteParams),
        this.eventLogContext$,
      ])
        .pipe(take(1))
        .subscribe(([params, context]) => {
          const contextParams = { ...params };
          const {
            scenarioCTRId = parseInt(contextParams.ctrId, 10),
            scenarioId,
            message,
          } = this.commentFormGroup.getRawValue();

          if (message.trim() === '') {
            return;
          }

          if (scenarioCTRId) {
            contextParams.scenarioCTRId = scenarioCTRId;
            contextParams.ctrId = scenarioCTRId;
          }

          if (scenarioId) {
            contextParams.scenarioId = scenarioId;
          }

          this.store.dispatch(
            postCommentAction({
              context: 'FilterComponent::sendComment',
              payload: {
                context: context.name,
                id: contextParams[context.keyName],
                message,
                ...contextParams,
              },
            })
          );

          this.commentFormGroup.patchValue(
            {
              message: null,
            },
            { onlySelf: false, emitEvent: true }
          );
        })
    );
  }

  public inputControl(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.sendComment();
      event.stopPropagation();
    }
  }
}
