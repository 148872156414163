<nav class="sidebar" style="background-color: {{ sideNavbarColor }}">
  <mat-nav-list>
    <ng-container *ngIf="userIsRequestor$ | async">
      <mat-list-item
        class="section-header"
        routerLink="/dashboard/requestor"
        [class.active]="showRequestorContext$ | async"
        data-test="sidenav-requestor-user-role-link"
      >
        <a routerLink="/dashboard/requestor">Requestor </a></mat-list-item
      >

      <ng-container *ngIf="showRequestorContext$ | async">
        <ng-container
          *ngIf="
            (userIsRequestor$ | async) &&
            (showAddProjectButton$ | async) &&
            (showNewProject$ | async) === false
          "
        >
          <mat-list-item data-test="sidenav-add-project-link">
            <a routerLink="/project/create">
              <mat-icon matListIcon>add</mat-icon> Add project
            </a>
          </mat-list-item>
        </ng-container>

        <ng-container *ngIf="sideNavConfiguration$ | async as projectData">
          <ng-container *ngIf="projectHeading$ | async as projectHeading">
            <ng-container *ngIf="(projectView$ | async) && projectHeading">
              <mat-list-item
                *ngIf="showNewProject$ | async; else projectHeadingTemplate"
                class="project-name"
                ><a class="active">New project</a>
              </mat-list-item>

              <ng-template #projectHeadingTemplate>
                <mat-list-item
                  *ngIf="(showNewProject$ | async) === false"
                  class="project-name"
                >
                  <a
                    routerLinkActive="active"
                    [routerLink]="['/project', projectData.projectId, 'edit']"
                    >{{ projectHeading }}</a
                  >
                </mat-list-item>
              </ng-template>

              <mat-list-item
                *ngIf="projectData.showDocuments"
                class="documents"
                data-test="sidenav-documents-link"
              >
                <a
                  routerLinkActive="active"
                  [routerLink]="['/documents/', projectData.projectId]"
                >
                  <mat-icon class="documents" matListIcon>feed</mat-icon>
                  Documents
                </a>
              </mat-list-item>

              <ng-container
                *ngFor="let scenario of projectData.scenarios; let i = index"
              >
                <mat-list-item
                  class="scenario"
                  [matTooltip]="getScenarioTooltip(scenario)"
                  [attr.data-test]="
                    'sidenav-project-id-' +
                    projectData.projectId +
                    '-scenario-id-' +
                    scenario.scenarioId +
                    '-link'
                  "
                >
                  <a
                    routerLinkActive="active"
                    [routerLink]="[
                      '/viewscenario',
                      projectData.projectId,
                      scenario.scenarioId
                    ]"
                  >
                    <app-scenario-status-icon
                      class="scenario-icon"
                      [scenarioNumber]="scenario.scenarioNo"
                      [scenarioId]="scenario.scenarioId"
                      [scenarioStatus]="scenario.scenarioStatus"
                      [scenarioSubmitted]="scenario.submitted"
                    ></app-scenario-status-icon>
                    {{ scenario.scenarioName }}
                    <mat-icon
                      *ngIf="scenario.baseCase"
                      title="Base case"
                      class="basecase-icon"
                      >star
                    </mat-icon>
                  </a>
                </mat-list-item>
              </ng-container>

              <ng-container *ngIf="(showNewProject$ | async) === false">
                <mat-list-item
                  *ngIf="showNewScenario$ | async; else addNewScenarioTemplate"
                  class="scenario active"
                  data-test="sidenav-new-scenario-link"
                >
                  <a class="active">
                    <app-scenario-status-icon
                      class="scenario-icon"
                      scenarioNumber="+"
                      scenarioId=""
                      scenarioStatus="Not started"
                    ></app-scenario-status-icon>
                    New scenario
                  </a>
                </mat-list-item>

                <ng-template #addNewScenarioTemplate>
                  <mat-list-item data-test="sidenav-add-scenario-link">
                    <a [routerLink]="['/scenario', projectData.projectId]">
                      <mat-icon matListIcon>add</mat-icon> Add scenario
                    </a>
                  </mat-list-item>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="projectData.loading"
          ></mat-progress-bar>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="userIsEngineer$ | async">
      <mat-divider *ngIf="userIsRequestor$ | async"></mat-divider>

      <mat-list-item
        *ngIf="userIsEngineer$ | async"
        class="section-header"
        routerLink="/dashboard/engineer"
        [class.active]="showEngineerContext$ | async"
        data-test="sidenav-engineer-user-role-link"
        ><a routerLink="/dashboard/engineer">Engineering </a></mat-list-item
      >

      <ng-container *ngIf="showEngineerContext$ | async">
        <ng-container *ngIf="sideNavConfiguration$ | async as projectData">
          <ng-container *ngIf="projectHeading$ | async as projectHeading">
            <ng-container *ngIf="(projectView$ | async) && projectHeading">
              <mat-list-item class="project-name" [disableRipple]="true">
                <a class="disabled">{{ projectHeading }}</a>
              </mat-list-item>

              <mat-list-item
                *ngIf="projectData.showDocuments"
                class="documents"
                data-test="sidenav-documents-link"
              >
                <a
                  routerLinkActive="active"
                  [routerLink]="['/view/documents/', projectData.projectId]"
                >
                  <mat-icon class="documents" matListIcon>feed</mat-icon>
                  Documents
                </a>
              </mat-list-item>

              <div class="ctr-buttons-wrapper">
                <button
                  mat-stroked-button
                  (click)="setConfigurationToOwn()"
                  [ngClass]="showOwnSCTRS ? 'selected-button' : 'plain-button'"
                  class="ctr-button condensed"
                >
                  Show my CTRs
                </button>
                <button
                  mat-stroked-button
                  (click)="setConfigurationToAll()"
                  [ngClass]="showOwnSCTRS ? 'plain-button' : 'selected-button'"
                  class="ctr-button condensed"
                >
                  Show all CTRs
                </button>
              </div>

              <ng-container
                *ngFor="let scenario of projectData.scenarios; let i = index"
              >
                <mat-list-item
                  class="scenario"
                  [disableRipple]="true"
                  [matTooltip]="getScenarioTooltip(scenario)"
                  [attr.data-test]="
                    'sidenav-project-id-' +
                    projectData.projectId +
                    '-scenario-id-' +
                    scenario.scenarioId +
                    '-link'
                  "
                >
                  <a class="disabled">
                    <app-scenario-status-icon
                      class="scenario-icon"
                      [scenarioNumber]="scenario.scenarioNo"
                      [scenarioId]="scenario.scenarioId"
                      [scenarioStatus]="scenario.scenarioStatus"
                      [scenarioSubmitted]="scenario.submitted"
                    ></app-scenario-status-icon>
                    {{ scenario.scenarioName }}
                    <mat-icon
                      *ngIf="scenario.baseCase"
                      title="Base case"
                      class="basecase-icon"
                      >star
                    </mat-icon>
                  </a>
                </mat-list-item>
                <mat-list-item
                  class="ctr"
                  *ngFor="let ctr of scenario.sctrs"
                  [attr.data-test]="
                    'sidenav-project-id-' +
                    projectData.projectId +
                    '-scenario-id-' +
                    scenario.scenarioId +
                    '-ctr-id-' +
                    ctr.scenarioCTRId +
                    '-link'
                  "
                >
                  <a
                    class="inner-menu"
                    routerLinkActive="active"
                    [routerLink]="[
                      '/view/ctr',
                      projectData.projectId,
                      scenario.scenarioId,
                      ctr.scenarioCTRId
                    ]"
                  >
                    <app-ctr-status-icon [ctrStatus]="ctr.scenarioCTRStatus">
                    </app-ctr-status-icon>
                    <span class="ctr-name">
                      {{ ctr?.ctrShortName
                      }}{{
                        scenario.scenarioStatus === 'On hold'
                          ? ' (read-only)'
                          : ''
                      }}
                    </span>
                  </a>
                </mat-list-item>
              </ng-container>
            </ng-container>
          </ng-container>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="projectData.loading"
          ></mat-progress-bar>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isAdmin$ | async">
      <mat-divider
        *ngIf="(userIsEngineer$ | async) || (userIsRequestor$ | async)"
      ></mat-divider>

      <mat-list-item
        class="section-header nav-item"
        [class.active]="showAdminContext$ | async"
        data-test="sidenav-admin-link"
        routerLink="/users"
        ><a routerLink="/users">Admin</a></mat-list-item
      >

      <ng-container *ngIf="showAdminContext$ | async">
        <mat-list-item data-test="sidenav-activities-link">
          <a routerLinkActive="active" routerLink="/view/activities"
            >Activities</a
          >
        </mat-list-item>
        <mat-list-item data-test="sidenav-clients-link">
          <a routerLinkActive="active" routerLink="/view/clients">Clients</a>
        </mat-list-item>
        <mat-list-item data-test="sidenav-currencies-link">
          <a routerLinkActive="active" routerLink="/view/currencies"
            >Currencies</a
          >
        </mat-list-item>
        <mat-list-item data-test="sidenav-entities-link">
          <a routerLinkActive="active" routerLink="/view/entities">Entities</a>
        </mat-list-item>
        <mat-list-item data-test="sidenav-pxd-link">
          <a routerLinkActive="active" routerLink="/view/pxd"
            >Products & disciplines</a
          >
        </mat-list-item>
        <mat-list-item data-test="sidenav-roles-and-rates-link">
          <a routerLinkActive="active" routerLink="/view/rolesandrates"
            >Roles & rates</a
          >
        </mat-list-item>
        <mat-list-item data-test="sidenav-users-link">
          <a routerLinkActive="active" routerLink="/users">Users</a>
        </mat-list-item>
      </ng-container>
    </ng-container>

    <mat-divider
      *ngIf="
        (userIsEngineer$ | async) ||
        (userIsRequestor$ | async) ||
        (isAdmin$ | async)
      "
      class="sub-container"
    ></mat-divider>

    <mat-list-item data-test="sidenav-release-notes-link">
      <a routerLinkActive="active" routerLink="/release-notes">
        <mat-icon class="relese-notes" matListIcon>widgets</mat-icon> Release
        Notes
      </a>
    </mat-list-item>

    <mat-list-item data-test="sidenav-kpi-link">
      <a
        class="sidebar-project"
        routerLinkActive="active"
        routerLink="/view/kpi"
      >
        <mat-icon class="kpi" matListIcon>bar_chart</mat-icon> KPI & Export
      </a>
    </mat-list-item>

    <mat-list-item data-test="sidenav-user-guide-link">
      <a routerLinkActive="active" routerLink="/userguide">
        <mat-icon class="user-guide" matListIcon>import_contacts</mat-icon> User
        Guide
      </a>
    </mat-list-item>

    <mat-list-item data-test="sidenav-contact-link">
      <a routerLinkActive="active" routerLink="/contact">
        <mat-icon class="contact" matListIcon>contact_page</mat-icon> Contact
      </a>
    </mat-list-item>
  </mat-nav-list>

  <div class="version-container">eCTR - Release (Lenexa) 6.11.2024</div>
</nav>
