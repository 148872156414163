import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { selectAllActiveEntities } from '@app/collections/entities/store/entities.selectors';
import {
  updateProjectOwnerAction,
  updateProjectOwnerSuccessAction,
} from '@app/collections/projects/store/projects.actions';
import { projectsSelectors } from '@app/collections/projects/store/projects.selectors';
import { selectAllRequestors } from '@app/collections/users/store/users.selectors';
import { UserRole } from '@app/core/store/core.reducer';
import { UserDto } from '@app/models/backendModel';
import { IGetEntitiesResponseItem } from '@app/models/entities';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, map, of, startWith, switchMap } from 'rxjs';

export interface IEditOwnerDialogData {
  projectId: string;
}

export interface EditOwnerForm {
  entityId: FormControl<number>;
  userId: FormControl<number>;
  makeRequestorOfAllCtr: FormControl<boolean>;
  makeOwnerOfAllScenarios: FormControl<boolean>;
}

@Component({
  selector: 'app-edit-owner-dialog',
  templateUrl: './edit-owner-dialog.component.html',
  styleUrls: ['./edit-owner-dialog.component.scss'],
})
export class EditOwnerDialogComponent implements OnInit {
  public editOwnerFormGroup: FormGroup<EditOwnerForm>;
  public makeRequestorOfAllCtrs = false;
  public makeOwnerOfAllScenarios = false;
  public isUpdateOwnerDisabled = true;
  public originalEntityId: number;
  public originalOwnerId: number;
  public filteredEntities$: Observable<IGetEntitiesResponseItem[]>;
  public filteredProjectOwners$: Observable<UserDto[]>;
  public entitiesMap: Map<number, string>;
  public ProjectOwnersMap: Map<number, string>;
  public sendNotification = true;

  public entities$: Observable<IGetEntitiesResponseItem[]> = this.store.select(
    selectAllActiveEntities
  );

  public projectOwners$: Observable<UserDto[]> =
    this.store.select(selectAllRequestors);

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditOwnerDialogData,
    private action$: Actions
  ) {}

  private createEditOwnerFormGroup(
    originalEntityId: number,
    originalOwnerId: number
  ): void {
    this.editOwnerFormGroup = this.formBuilder.group<EditOwnerForm>({
      entityId: this.formBuilder.control(originalEntityId),
      userId: this.formBuilder.control(originalOwnerId),
      makeRequestorOfAllCtr: this.formBuilder.control(true),
      makeOwnerOfAllScenarios: this.formBuilder.control(true),
    });
  }

  private toggleUpdateOwnerEnabled(): void {
    this.editOwnerFormGroup.controls.userId.valueChanges.subscribe((value) => {
      if (value) {
        this.isUpdateOwnerDisabled = false;
      } else {
        this.isUpdateOwnerDisabled = true;
      }
    });
  }

  private getProjectOwnerDataFromStore(): void {
    this.store
      .select(
        projectsSelectors[UserRole.REQUESTOR].selectProjectByIdFactory(
          parseFloat(this.data.projectId)
        )
      )
      .subscribe((data) => {
        this.originalEntityId = data.executingEntityId;
        this.originalOwnerId = data.requestorId;
      });
  }

  private populateEntitiesMap(): void {
    this.entities$.subscribe((entities) => {
      entities.forEach((entity) => {
        this.entitiesMap.set(entity.entityId, entity.enggCenter);
      });
    });
  }

  private populateProjectOwnersMap(): void {
    this.projectOwners$.subscribe((requestors) => {
      requestors.forEach((requestor) => {
        this.ProjectOwnersMap.set(requestor.userId, requestor.fullName);
      });
    });
  }

  public filterEntities(): void {
    this.editOwnerFormGroup
      .get('entityId')
      .valueChanges.pipe(
        startWith(''),
        switchMap((value) => {
          if (typeof value === 'string') {
            return this.entities$.pipe(
              map((entities) =>
                entities
                  .filter((entity) =>
                    entity.enggCenter
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  )
                  .sort((a, b) => a.enggCenter.localeCompare(b.enggCenter))
              )
            );
          } else {
            return of([]);
          }
        })
      )
      .subscribe(
        (filteredEntities) => (this.filteredEntities$ = of(filteredEntities))
      );
  }

  public filterProjectOwners(): void {
    this.editOwnerFormGroup.controls.entityId.valueChanges
      .pipe(
        startWith(this.editOwnerFormGroup.controls.entityId.value),
        switchMap((entityId) => {
          if (this.editOwnerFormGroup.controls.entityId.dirty) {
            this.editOwnerFormGroup.controls.userId.patchValue(0);
          }
          return this.projectOwners$.pipe(
            map((projectOwners) =>
              projectOwners.filter(
                (projectOwner) => projectOwner.entityId === entityId
              )
            ),
            switchMap((filteredProjectOwners) =>
              this.editOwnerFormGroup.controls.userId.valueChanges.pipe(
                startWith(''),
                map((value) => {
                  if (typeof value === 'string') {
                    return filteredProjectOwners
                      .filter((projectOwner) =>
                        projectOwner.fullName
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      )
                      .sort((a, b) => a.fullName.localeCompare(b.fullName));
                  } else {
                    return [];
                  }
                })
              )
            )
          );
        })
      )
      .subscribe(
        (filteredProjectOwners) =>
          (this.filteredProjectOwners$ = of(filteredProjectOwners))
      );
  }

  displayEntity(entityId: number): string {
    return this.entitiesMap.get(entityId) || '';
  }

  displayProjectOwner(userId: number): string {
    return this.ProjectOwnersMap.get(userId) || '';
  }

  ngOnInit(): void {
    this.entitiesMap = new Map<number, string>();
    this.ProjectOwnersMap = new Map<number, string>();

    this.displayEntity = this.displayEntity.bind(this);
    this.displayProjectOwner = this.displayProjectOwner.bind(this);

    this.getProjectOwnerDataFromStore();
    this.createEditOwnerFormGroup(this.originalEntityId, this.originalOwnerId);
    this.toggleUpdateOwnerEnabled();
    this.populateEntitiesMap();
    this.populateProjectOwnersMap();

    this.filterEntities();
    this.filterProjectOwners();
  }

  public Cancel(): void {
    this.dialogRef.close();
  }

  public updateOwner(): void {
    const model = this.editOwnerFormGroup.getRawValue();
    this.store.dispatch(
      updateProjectOwnerAction({
        payload: {
          ...model,
          projectId: parseFloat(this.data.projectId),
          sendNotification: this.sendNotification,
        },
      })
    );

    this.action$.pipe(ofType(updateProjectOwnerSuccessAction)).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
