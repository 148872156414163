import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { IGetUsersResponse } from '@models/users';

import { UsersApiService } from '../users-api.service';

import { MediatorService } from '@app/mediator.service';
import {
  ActiveUsersQuery,
  CreateUserCommand,
  CreateUserPublicCommand,
  InactiveUsersQuery,
  UpdateUserCommand,
  UserDto,
} from '@app/models/backendModel';
import { environment } from '@environments/environment';
import { TypedAction } from '@ngrx/store/src/models';
import {
  createUserAction,
  createUserFailureAction,
  createUserPublicAction,
  createUserSuccessAction,
  identifyCurrentUserAction,
  identifyCurrentUserFailureAction,
  identifyCurrentUserSuccessAction,
  setMsalUserAction,
  updateUserAction,
  updateUserFailureAction,
  updateUserSuccessAction,
  UsersActions,
} from './users.actions';
import { LoadingStateEnum } from './users.reducer';
import { selectUsersListLoadedState } from './users.selectors';

@Injectable()
export class UsersEffects {
  public reloadUsers$<T>(inputObservable: Observable<T>): Observable<
    | ({
        users: UserDto[];
      } & TypedAction<'[Collaboration] Get Active Users Query SUCCESS'>)
    | TypedAction<'[Collaboration] Get Active Users Query FAILURE'>
  > {
    return inputObservable.pipe(
      throttleTime(1000),
      tap(() => this.store.dispatch(UsersActions.getInactiveUsers())),
      switchMap(() =>
        this.mediatorService
          .send<ActiveUsersQuery, UserDto[]>('ActiveUsersQuery', {})
          .pipe(
            map((payload) =>
              UsersActions.getActiveUsersQuerySuccess({
                users: payload,
              })
            ),
            catchError(() => of(UsersActions.getActiveUsersQueryFailure()))
          )
      )
    );
  }
  public getUsersGetAllUsers$ = createEffect(() =>
    this.reloadUsers$(
      this.actions$.pipe(
        ofType(UsersActions.getActiveUsersQuery),
        withLatestFrom(this.store.select(selectUsersListLoadedState)),
        filter(
          ([, usersListLoadState]) =>
            ![LoadingStateEnum.LOADED].includes(usersListLoadState)
        )
      )
    )
  );

  public forceGetUsersGetAllUsers$ = createEffect(() =>
    this.reloadUsers$(
      this.actions$.pipe(
        ofType(UsersActions.forceGetActiveUsersQuery),
        filter(() => !environment.authentication)
      )
    )
  );

  public getInactiveUsersQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getInactiveUsers),
      switchMap(() =>
        this.mediatorService
          .send<InactiveUsersQuery, UserDto[]>('InactiveUsersQuery', {})
          .pipe(
            map((payload) =>
              UsersActions.getInactiveUsersSuccess({
                users: payload,
              })
            )
          )
      )
    )
  );

  public getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identifyCurrentUserAction),
      switchMap((action) =>
        this.usersApiService.getUsers().pipe(
          map((user) =>
            identifyCurrentUserSuccessAction({
              trigger: action,
              context: 'UsersEffects::getUsers$',
              payload: { user, ready: false },
            })
          ),
          catchError((err) => {
            console.error(`Issues when identifying current user`, err);

            return of(
              identifyCurrentUserFailureAction({
                trigger: action,
                context: 'UsersEffects::getUsers$',
                payload: { user: action.payload.msalUser },
              })
            );
          })
        )
      )
    )
  );

  public setTempUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identifyCurrentUserFailureAction),
      map((action) => {
        const user = action.payload.user;

        return setMsalUserAction({
          trigger: action,
          context: 'UsersEffects::setTempUser$',
          payload: {
            user: {
              userId: null,
              entityId: null,
              businessSegmentId: 1,
              isAdmin: false,
              isRequestor: false,
              isEngineer: false,
              ...user,
            } as IGetUsersResponse,
          },
        });
      })
    )
  );

  public createUserPublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserPublicAction),
      switchMap((action) =>
        this.mediatorService
          .sendPublicRequest<CreateUserPublicCommand, number>(
            'CreateUserPublicCommand',
            action.payload.userData
          )
          .pipe(
            map((userId) =>
              createUserSuccessAction({
                trigger: action,
                context: 'UsersEffects::createUser$',
                payload: { user: { ...action.payload.userData, userId } },
              })
            ),
            catchError(() =>
              of(
                createUserFailureAction({
                  trigger: action,
                  context: 'UsersEffects::createUser$',
                })
              )
            )
          )
      )
    )
  );

  public createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAction),
      switchMap((action) =>
        this.mediatorService
          .send<CreateUserCommand, number>(
            'CreateUserCommand',
            action.payload.userData
          )
          .pipe(
            map((userId) =>
              createUserSuccessAction({
                trigger: action,
                context: 'UsersEffects::createUser$',
                payload: { user: { ...action.payload.userData, userId } },
              })
            ),
            catchError(() =>
              of(
                createUserFailureAction({
                  trigger: action,
                  context: 'UsersEffects::createUser$',
                })
              )
            )
          )
      )
    )
  );

  public updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserAction),
      switchMap((action) =>
        this.mediatorService
          .send<UpdateUserCommand>('UpdateUserCommand', action.payload.userData)
          .pipe(
            map(() =>
              updateUserSuccessAction({
                trigger: action,
                context: 'UsersEffects::updateUser$',
                payload: {
                  userData: {
                    ...action.payload.userData,
                  },
                },
              })
            ),
            catchError(() =>
              of(
                updateUserFailureAction({
                  trigger: action,
                  context: 'UsersEffects::updateUser$',
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private usersApiService: UsersApiService,
    private store: Store,
    private mediatorService: MediatorService
  ) {}
}
