<div class="header">
  <h1>Details for {{ selectedCtrData.ctrShortName }}</h1>
  <button
    class="close-button"
    color="primary"
    mat-raised-button
    mat-dialog-close
    data-test="detailed-view-close-details-button"
  >
    Close details
  </button>
</div>

<mat-card appearance="outlined" class="general-info">
  <mat-card-header>
    <mat-card-title>General information</mat-card-title>
  </mat-card-header>
  <table class="general-info-table" aria-label="calendarization for roles">
    <tr>
      <th>CTR ID</th>
      <td data-test="ctr-id-value">{{ selectedCtrData.scenarioCTRId }}</td>
    </tr>
    <tr>
      <th>Owner</th>
      <td data-test="ctr-owner-value">{{ selectedCtrData.owner }}</td>
    </tr>
    <tr>
      <th>Start date</th>
      <td data-test="start-date-value">
        {{ selectedCtrData.startDateAsDate | date }}
      </td>
    </tr>
    <tr>
      <th>End date</th>
      <td data-test="end-date-value">
        {{ selectedCtrData.endDateAsDate | date }}
      </td>
    </tr>
    <ng-container *ngIf="totalAmounts$ | async as totalAmounts">
      <tr>
        <th>Total hours</th>
        <td data-test="total-hours-value">
          {{ totalAmounts.totalHours | integer : true }}
        </td>
      </tr>
      <tr>
        <th>Total FTE</th>
        <td data-test="total-fte-value">
          {{ totalAmounts.totalFte | oneDecimal : true }}
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="isRequestorMode">
      <th>Total cost</th>
      <td data-test="total-cost-value">
        {{ selectedCtrData.totalCost | integer : true }} USD
      </td>
    </tr>
    <tr>
      <th>Executing centers</th>
      <td data-test="executing-center-value">{{ entitiesNames }}</td>
    </tr>
  </table>
</mat-card>

<app-calendarization-table-roles
  [ctrId]="selectedCtrData.scenarioCTRId"
  [isRequestorMode]="isRequestorMode"
></app-calendarization-table-roles>
